/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import BlackHatSplash from '../Components/BlackHatSplash/BlackHatSplash';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Dashboard from '../Components/Dashboard/Dashboard';
import PasswordPage from '../Components/PasswordPage/PasswordPage';
import DCPassword from '../Components/DCPassword/DCPassword'
import Invoice from '../Components/Dashboard/Invoice/InvoiceWrapper'
import Terms from '../Components/Terms/TermsWrapper'
import Privacy from '../Components/Privacy/TermsWrapper'
import Shop from '../Components/Shop/ShopWrapper'

interface IProps {
  userData: any;
  isAuthenticated: boolean;
  setIsAuthenticated: any;
  isLoggedIn: any;
  setIsLoggedIn: any;
  setNewMemberHasPurchased: any;
}


const Routes: React.FC<IProps> = (props: any) => {
  const {
    userData, isAuthenticated, setIsAuthenticated, isLoggedIn, setIsLoggedIn, setNewMemberHasPurchased,
  } = props;


  const [hasPermission, setHasPermission] = useState(false);
  const [hasAdminPermission, setHasAdminPermission] = useState(false);


  useEffect(() => {
    if (userData.role === 'admin') {
      setHasPermission(true);
      setHasAdminPermission(true);
    } else if (userData.role === 'member') {
      setHasPermission(true);
    }
  }, [userData]);

  useEffect(() => {

  })
  return (
    <>

      <Route exact path="/">
        <BlackHatSplash isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Route>
      <Route exact path="/password">
        <PasswordPage userData={userData} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsAuthenticated={setIsAuthenticated} setNewMemberHasPurchased={setNewMemberHasPurchased} />
      </Route>

      <Route exact path="/dcpassword">
        <DCPassword userData={userData} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsAuthenticated={setIsAuthenticated} setNewMemberHasPurchased={setNewMemberHasPurchased} />
      </Route>
      <Route hasPermission={hasPermission} path="/invoice">
        <Invoice isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </Route>

      <Route hasPermission={hasPermission} path="/privacy">
        <Privacy isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </Route>
      <Route hasPermission={hasPermission} path="/terms">
        <Terms isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </Route>
      <Route path="/shop">
        <Shop isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
      </Route>
      {/* <PrivateRoute hasPermission={hasPermission} path="/d">
        <Dashboard isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </PrivateRoute> */}
      {/* <Route path="/dashboard">

        <Dashboard isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </Route> */}
      <PrivateRoute hasPermission={hasPermission} path="/dashboard">
        <Dashboard isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userData={userData} setIsLoggedIn={setIsLoggedIn} />
      </PrivateRoute>
    </>
  );
};
export default Routes;
