// export const topLevelPath = '/scinet';

// export const getFrontEndPath = (path: string) => {
//    return `${topLevelPath}/${path}`;
// };

const getAPIPath = (path: string) => {
  const apiPath = 'api';
  if(path.startsWith('/')) path = path.slice(1)
  return `/${apiPath}/${path}`;
};

export default getAPIPath;
