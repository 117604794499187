
import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class NetnutService {
  static async GetPool1(id: string, data: any, onSuccess: any, onError: any) {
    await axios
      .post(getAPIPath(`nn-pool/pool-1/${id}`), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetReleasePool(onSuccess: any, onError: any) {
    await axios.get(getAPIPath('nn-pool/release-pool'), { withCredentials: true }).then(onSuccess).catch(onError);
  }

  static async GetRemainingStock(onSuccess: any, onError: any) {
    await axios.get(getAPIPath('nn-pool/stock'), { withCredentials: true }).then(onSuccess).catch(onError);
  }
}
