/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Row, Col, Jumbotron, Image, Button, Form, ButtonGroup,
} from 'react-bootstrap';
import './_generate.scss';
import ReactTypingEffect from 'react-typing-effect';
import regionList from '../../../helpers/regionList';
import DiscordService from '../../../services/DiscordService';
// import ProxyService from '../../../services/ProxyService';
import NetnutService from '../../../services/NetnutService';
// import RevenueService from '../../../services/RevenueService';
// import ProductService from '../../../services/ProductService';
// import UserService from '../../../services/UserService';
import SubService from '../../../services/SubWorks';
import ProxyCheapService from '../../../services/ProxyCheapService';
import cachedData from '../../../helpers/cacheData'

import countries from '../../../helpers/countries';

interface IProps {
  userData: any;
}


const Generate: React.FC<IProps> = (props: any) => {
  const netnutResi = 'resi';
  const proxyCheapResi = 'resi-non-expiry';
  const proxyWorksResi = 'resi-works';

  const { userData } = props;
  const [greeting, setGreeting] = useState('');
  const [userDashboardData, setUserDashboardData] = useState({}) as any;
  const [subWorksPool, setSubPool] = useState([]) as any;
  const [netnutDataRemaining, setNetnutDataRemaining] = useState('N/A');
  const [proxyCheapDataRemaining, setProxyCheapDataRemaining] = useState('N/A');
  const [packetStreamDataRemaining, setPacketStreamDataRemaining] = useState('N/A');
  const [subWorksDataRemaining, setSubRemaining] = useState('N/A');
  const [isFetchingDashboardData, setIsFetchingDashboardData] = useState(true);
  const [selectedProxyType, setSelectedProxyType] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [proxyAuthKey, setProxyAuthKey] = useState('');
  const [expirationDate, setExpirationDate] = useState() as any;
  const [regionIndex, setRegionIndex] = useState('');
  const [proxyType, setProxyType] = useState('Sticky');
  const [proxyQuantity, setProxyQuantity] = useState(0);
  const [proxyPool, setProxyPool] = useState('');
  const [regionID, setRegionID] = useState('1');
  const [proxyList, setProxyList] = useState('');
  const [isGenerated, setIsGenerated] = useState(false);
  const [proxyAuthMessage, setProxyAuthMessage] = useState('');
  const [selectPoolType, setSelectPoolType] = useState('store')
  const [proxySelectVal, setProxySelectVal] = useState('')

  const textAreaRef = useRef<HTMLDivElement>(null) as any;

  const getUserDetails = async () => {
    const onGetUserRoleSuccess = (response: any) => {
      setUserDashboardData(response.data);
      if (response.data.netnutData) {
        if (response.data.netnutData !== '0B') {
          setSelectedProxyType(netnutResi);
          const newDataString = response.data.netnutData;
          setNetnutDataRemaining(`${newDataString}GB`);
          const date = new Date(response.data.data_expiry);
          const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          setExpirationDate(newDate);
        }
      }

      if (response.data.proxyCheap) {
        if (response.data.proxyCheap.balance > 0) {
          setProxyCheapDataRemaining(`${response.data.proxyCheap.balance / 100}GB`);
          setSelectedProxyType(proxyCheapResi);
          setProxyAuthKey(response.data.proxyCheap.proxyAuthKey);
        }
      }
      if (response.data.packetStream) {
        if (response.data.packetStream.balance > 0) {
          setPacketStreamDataRemaining(`${response.data.packetStream.balance / 100}GB`);
          setSelectedProxyType(proxyCheapResi);
          setProxyAuthKey(response.data.packetStream.proxyAuthKey);
        }
      }
      if (response.data.subworksUserData) {
        let { subworksUserData } = response.data
        let { dataUsage, dataLimitMegaBytes } = subworksUserData
        console.log(dataUsage, dataLimitMegaBytes, 5555)
        let xx = dataLimitMegaBytes - (dataUsage / 1000000)
        // console.log(mbRemaining)
        let gb = xx / 1000
        let resultNum = parseFloat(gb.toFixed(2))
        setSubRemaining(resultNum + 'GB')
        setSelectedProxyType(proxyWorksResi);

      }

      setIsFetchingDashboardData(false);
    };

    if (JSON.stringify(cachedData.userRole) != "{}") {
      onGetUserRoleSuccess(cachedData.userRole)
      // setIsFetchingDashboardData(false)
    }
    const onGetUserRoleError = (error: any) => {
      console.log('ERROR: ', error.response);
    };
    await DiscordService.GetUserRole(userData.user_id, onGetUserRoleSuccess, onGetUserRoleError);
  };

  const getSubPool = async () => {
    console.log(`getSubPool() called`)
    let r = await SubService.getPools()
    // console.log(r)
    setSubPool(r)
  }


  useEffect(() => {
    getUserDetails();
    getSubPool();
  }, []);

  const setTimeGreeting = () => {
    const time = new Date().getHours();
    if (time < 10) {
      setGreeting('Good Morning');
    } else if (time < 20) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  };
  useEffect(() => {
    setTimeGreeting();
  }, []);


  if (isFetchingDashboardData) {
    return (
      <div
        style={{
          textAlign: 'center', position: 'relative', top: '50vh',
        }}
      >
        <ReactTypingEffect
          text=". . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>
    );
  }
  const getSelectPoolOpts = () => {
    console.log(`getting select options for ${selectedProxyType}`);
    let withStores = subWorksPool.filter(pool => pool.continent.includes('Stores'))
    let woStores = subWorksPool.filter(pool => !pool.continent.includes('Stores'))

    woStores = woStores.map((item, index) => {
      let nl = item.name.toLowerCase()
      if (item.name.includes('Ace Forest')) {
        item.name = item.name.replace('Ace Forest', 'Juiced')
      }
      console.log(nl)
      if (nl.includes('ace us fibre')) {
        item.name = 'Juiced US 1'
      }
      if (nl.includes('ace us wifi')) {
        item.name = 'Juiced US 2'
      }
      if (nl.includes('ace us mob')) {
        item.name = 'Juiced US Mobile'
      }
      if (nl.includes('ace us sat')) {
        item.name = 'Juiced US Backup 1'
      }

      if (nl.includes('ace us dsl')) {
        item.name = 'Juiced US Backup 2'
      }

      if (nl.includes('ace eu 1')) {
        item.name = 'Juiced EU 1'
      }
      if (nl.includes('ace eu 2')) {
        item.name = 'Juiced EU 2'
      }
      return item
    })
    let first = "Juiced"
    let first2 = "Juiced US"


    woStores.sort(function (x, y) { return x.name.includes(first) ? -1 : y.name.includes(first) ? 1 : 0; });
    woStores.sort(function (x, y) { return x.name.includes(first2) ? -1 : y.name.includes(first2) ? 1 : 0; });
    let pools
    if (selectPoolType == 'store') {
      pools = withStores
    } else {
      pools = woStores
    }
    let el
    if (selectedProxyType === netnutResi) {

      el = (<>
        <option label="" value="" />
        <option label="Release" value="Release">Release</option>
        {regionList.map((region, index) => (
          <>
            <option label={region} value={index}>{region}</option>
          </>
        ))}
      </>
      )
    } else if (selectedProxyType === proxyWorksResi) {
      el = (<>
        <option label="" value="" />
        {pools.map((item, index) => (
          <>
            <option value={item.name}>{item.name}</option>
          </>
        ))}
      </>
      )
    } else {
      el = (<>
        <option label="" value="" />
        {countries.map((country, index) => (
          <>
            <option value={country}>{country}</option>
          </>
        ))}
      </>
      )
    }
    // console.log(el)
    return el
  }


  const generateSessionIP = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }


  function genProxy(pool, isSticky, username, password) {
    let { domain } = pool

    console.log(`genning 1 proxy ${domain} ${isSticky} ${username} ${password}`)

    let port = randomIntFromInterval(pool.portStart, pool.portEnd);
    let result
    if (isSticky) {
      let id = generateSessionIP(10);
      let template1 = `${domain}:${port}:${username}:${password}-${id}`
      result = template1
    } else {
      let template1 = `${domain}:${port}:${username}:${password}`
      result = template1
    }
    // console.log(result,66666666)
    return result
  }
  function buildWorksList(isSticky) {
    /*Sticky: domain: port: username: password - { 10 digit alphanumeric random session id }
    Rotating: domain: port: username: password
    */
    // console.log(proxyPool,'fuckFUCKFUCKFUCUFUCK')
    let poolData = subWorksPool.find(s => s.name == proxyPool)
    if (!poolData) {
      console.error(`no pool found for name ${proxyPool}`)
      return []
    }
    let { subworksUserData } = userDashboardData
    let { name, pass } = subworksUserData
    console.log(`building works list using name ${name} and pass ${pass} with pool ${proxyPool} isSticky ${isSticky}`)
    let arr: any = []
    for (var i = 0; i < proxyQuantity; i++) {
      let p = genProxy(poolData, isSticky, name, pass);
      arr.push(p);
    }
    return arr
    // proxyQuantity
  }


  const handleGenerate = async (event: any) => {
    event.preventDefault();
    console.log(`generating list for ${selectedProxyType}`)
    if (selectedProxyType === netnutResi) {
      const onHandleGenerateSuccess = (response: any) => {
        setProxyList(response.data);
        setIsGenerated(true);
        setIsCopied(false);
      };
      const onHandleGenerateError = (error: any) => {
        console.log('ERROR: ', error.response);
      };
      event.preventDefault();

      if (proxyPool.toLowerCase() === 'release') {
        NetnutService.GetReleasePool((response: any) => {
          const liveServers = response.data;
          if (liveServers.length > 0) {
            const proxyUser = userDashboardData.netnutUserData.customerLogin;
            const proxyPassword = userDashboardData.netnutUserData.customerPassword;
            const arr = [] as any;
            const min = 1;
            const ipMax = 255;
            const ipNumber = Math.floor(Math.random() * (ipMax - min + 1) + min);
            while (arr.length < proxyQuantity) {
              const ipNumber = Math.floor(Math.random() * (ipMax - min + 1) + min);
              const serverUsed = liveServers[Math.floor(Math.random() * liveServers.length)];
              if (proxyType.toLowerCase() === 'rotating') {
                arr.push(`${serverUsed.ip}:33128:${proxyUser}:${proxyPassword}`);
              } else {
                arr.push(`${serverUsed.ip}:33128:${proxyUser}!a${ipNumber}:${proxyPassword}`);
              }
            }
            const proxy = arr.join('\n');
            return setProxyList(proxy);
          }
        }, (error: any) => console.log('ERROR: ', error.response));
        return;
      }
      const ysMex = /yeezysupply - mx/gmi;
      const ysCan = /yeezysupply - ca/gmi;
      const ysUS = /yeezysupply - us/gmi;
      const ftlUS = /footlocker - us/gmi;
      const defaultUS = /default - us/gmi;
      const defaultUK = /default - uk/gmi;
      const snkrs = /snkrs/gmi;
      const footsitesUK = /footsites - uk/gmi;
      const adidas = /adidas/gmi;
      const kith = /kith/gmi;
      const supreme = /supreme/gmi;
      const footsitesUS = /footsites - us/gmi;
      let proxyRegion = proxyPool;
      let proxySite;
      if (kith.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'kith';
      }
      if (snkrs.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'nike';
      }
      if (defaultUS.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'default';
      }
      if (defaultUK.test(proxyPool)) {
        proxyRegion = 'UK';
        proxySite = 'default';
      }

      if (footsitesUS.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'footlocker';
      }
      if (adidas.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'adidas';
      }
      if (supreme.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'supreme';
      }
      if (footsitesUK.test(proxyPool)) {
        proxyRegion = 'uk';
        proxySite = 'footlocker';
      }

      if (ysUS.test(proxyPool)) {
        proxyRegion = 'us';
        proxySite = 'adidas';
      }


      // if (ysMex.test(proxyPool)) {

      // }

      // if (ysCan.test(proxyPool)) {

      // }

      if (footsitesUK.test(proxyPool)) {
        proxyRegion = 'footlocker';
      }

      const proxyData = {
        proxyRegion,
        proxyAmount: proxyQuantity,
        proxyType,
        proxySite,
      };


      await NetnutService.GetPool1(userDashboardData.user_id, proxyData, onHandleGenerateSuccess, onHandleGenerateError);
    } else if (selectedProxyType === proxyCheapResi) {
      event.preventDefault();
      if (proxyType.toLowerCase() === 'rotating') {
        const loadBalancerNumber = Math.floor(Math.random() * (8 - 1 + 1) + 1);
        const rotatingIP = `ne${loadBalancerNumber}.blackhatproxies.dev:31112:${userDashboardData.proxyCheap.username}:${proxyAuthKey}_country-${proxyPool.replace(/\s+/g, '')}`;
        setProxyList(rotatingIP);
      } else if (proxyType.toLowerCase() === 'sticky') {
        const arr = [] as string[];
        while (arr.length < proxyQuantity) {
          const loadBalancerNumber = Math.floor(Math.random() * (8 - 1 + 1) + 1);
          const siteRegex = /footsites|shopify|yeezysupply/gmi;
          let usedPool = proxyPool;

          if (siteRegex.test(proxyPool)) {
            usedPool = 'United States';
          }
          if (proxyPool === 'YeezySupply - All') {
            const ysAll = ['United States', 'Mexico', 'Canada'];
            const randomPool = Math.floor(Math.random() * (3 - 0) + 0);
            usedPool = ysAll[randomPool];
          }

          if (userDashboardData.proxyCheap) {
            if (userDashboardData.proxyCheap.balance > 0) {
              arr.push(`ne${loadBalancerNumber}.blackhatproxies.dev:31112:${userDashboardData.proxyCheap.username}:${proxyAuthKey}_country-${usedPool.replace(/\s+/g, '')}_session-${generateSessionIP(12)}`);
            } else {
              arr.push(`ne${loadBalancerNumber}.blackhatproxies.dev:31112:${userDashboardData.packetStream.username}:${proxyAuthKey}_country-${usedPool.replace(/\s+/g, '')}_session-${generateSessionIP(12)}`);
            }
          } else {
            arr.push(`ne${loadBalancerNumber}.blackhatproxies.dev:31112:${userDashboardData.packetStream.username}:${proxyAuthKey}_country-${usedPool.replace(/\s+/g, '')}_session-${generateSessionIP(12)}`);
          }

        }
        const proxyCheapList = arr.join('\n');
        setProxyList(proxyCheapList);
      }
    } else if (selectedProxyType == proxyWorksResi) {
      console.log(`fuck fuc fuck`)
      let result
      // alert(proxyType)
      if (proxyType.toLowerCase() === 'rotating') {
        result = buildWorksList(false)
        // setProxyList(rotatingIP);
      } else if (proxyType.toLowerCase() === 'sticky') {
        result = buildWorksList(true);

      }
      console.log(`built works arr of len ${result.length}`)
      let s = result.join('\n')
      setProxyList(s)
    };
  }

  const handleCopyToClipboard = (event: any) => {
    event.preventDefault();
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    event.target.focus();
    setIsCopied(true);
    // setCopySuccess('Copied!');
  };

  const handleResetProxyAuthKey = (event: any) => {
    const onResetSuccess = (response: any) => {
      setProxyAuthMessage('Your proxy auth key has been reset.');
      setProxyAuthKey(response.data);
    };

    const onResetError = (error: any) => {
      setProxyAuthMessage('Error resetting proxy auth key.');
    };

    const data = {
      username: userDashboardData.proxyCheap.username,
      userID: userDashboardData.user_id,
    };

    ProxyCheapService.ResetProxyAuthKey(data, onResetSuccess, onResetError);
  };


  return (
    <Row style={{ width: '100%' }}>
      <Col>
        <Jumbotron className="user-jumbotron">
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Col xs="auto">
              <h1 className="user-greeting">
                {greeting}
                ,
                {userDashboardData.username}
              </h1>
            </Col>
            <Col xs="auto">
              <Image
                className="discord-avatar"
                roundedCircle
                src={
                  userDashboardData.avatar
                    ? `https://cdn.discordapp.com/avatars/${userDashboardData.user_id}/${userDashboardData.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${userDashboardData.discriminator % 5}.png`
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/*{(netnutDataRemaining != 'N/A') && (
                <h6>
                  Total 60-Day Plan Left:
                  {netnutDataRemaining ? netnutDataRemaining : "N/A"}
                </h6>
              )} */}
              {/*{proxyCheapDataRemaining !== 'N/A' && (
                <h6>
                  Total Non-Expiry Data Left (Old):
                  {proxyCheapDataRemaining}
                </h6>
              )} */}
              {/*{(packetStreamDataRemaining !== 'N/A') && (
                <h6>
                  Total Available Non-Expiry Data Left:
                  {packetStreamDataRemaining}
                </h6>
              )}*/}
              <h6>
                MagicHatter Data Left: {subWorksDataRemaining}
              </h6>
            </Col>
          </Row>

          {(proxyCheapDataRemaining != 'N/A') && (
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col xs="auto">
                <Button
                  className="bind-button"
                  onClick={handleResetProxyAuthKey}
                >
                  Reset Proxy Authorization Key
                </Button>
                {proxyAuthMessage && (
                  <h6>
                    {proxyAuthMessage}
                  </h6>
                )}
              </Col>
            </Row>
          )}

        </Jumbotron>
        <Row noGutters style={{ marginTop: '2rem' }}>
          <Col>
            <ButtonGroup size="lg" aria-label="Basic example">
              {(userDashboardData.subWorksData || true) && (
                <Button
                  disabled={userDashboardData.subworksUserData ? (!userDashboardData.subworksUserData.name) : true}
                  className={(selectedProxyType === proxyWorksResi) ? 'purchase-button' : ''}
                  variant="secondary"
                  onClick={() => {
                    setSelectedProxyType(proxyWorksResi);
                  }}
                >
                  MagicHatter Resi
                </Button>

              )}


            </ButtonGroup>
          </Col>
        </Row>

        <Row noGutters style={{ marginTop: '2rem' }}>
          <Col xs={12}>
            <Form style={{ textAlign: 'left', fontSize: '1.5rem' }}>

              <Row style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                <Col xs={{ span: 3 }}>
                  <Form.Label>Proxy Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter proxy quantity"
                    value={proxyQuantity}
                    onChange={(event: any) => {
                      setProxyQuantity(event.target.value);
                    }}
                  />
                </Col>

                <Col xs={3}>
                  <Form.Label>Proxy Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(event: any) => {
                      let x = event.target.value;
                      if (!x) return
                      let type = x.split(':')[0]
                      let pooltype = x.split(':')[1]
                      setProxySelectVal(x)
                      setProxyType(type);
                      setSelectPoolType(pooltype);
                    }}
                    value={proxySelectVal}
                  >

                    <option value="sticky:store" >Sticky - Store Specific </option>
                    <option value="sticky:country">Sticky - Countries</option>
                    <option value="rotating:store">Rotating - Store Specific</option>
                    <option value="rotating:country">Rotating - Countries</option>
                  </Form.Control>
                </Col>


                <Col xs={3}>
                  <Form.Label>Store or Country</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(event: any) => {
                      if (selectedProxyType === netnutResi) {
                        console.log('EVENT: ', event.target.value);
                        if (event.target.value.toLowerCase() === 'release') {
                          setProxyPool(event.target.value);
                          setRegionIndex(event.target.value);
                        } else {
                          setProxyPool(regionList[event.target.value]);
                          setRegionIndex(event.target.value);
                          setRegionID(regionList[event.target.value]);
                        }
                      } else {
                        // console.log(event.target.value,69999)
                        setProxyPool(event.target.value);
                        setRegionIndex(event.target.value);
                      }
                    }}
                    value={regionIndex}
                  >
                    {getSelectPoolOpts()}

                  </Form.Control>
                </Col>

                <Col className="t-hiddens md:t-block">
                  <Button
                    variant="primary"
                    className="generate-button"
                    type="submit"
                    onClick={handleGenerate}
                  >
                    GENERATE
                  </Button>
                </Col>
                <Col className="t-hiddens md:t-block">
                  <Button
                    variant="primary"
                    className="generate-button"
                    type="submit"
                    onClick={handleCopyToClipboard}
                  >
                    COPY
                  </Button>
                </Col>


              </Row>
            </Form>

            <Row style={{ marginTop: '2rem', textAlign: 'left' }}>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label style={{ fontSize: '2rem' }}>
                    <span>
                      {isGenerated ? (`Number of Proxies Generated:
                    ${proxyQuantity} ${proxyType} ${proxyPool}`) : (`Proxies to Generate: ${proxyQuantity} ${proxyType} ${proxyPool} `)}
                    </span>

                    {isCopied && (
                      <h6>Copied!</h6>
                    )}
                  </Form.Label>
                  <Form.Control className="list-area t-rounded" as="textarea" rows={15} defaultValue={proxyList} ref={textAreaRef} />
                </Form.Group>
              </Col>
            </Row>
          </Col>

        </Row>
      </Col>
    </Row >

  );
};

export default Generate;
