/* eslint-disable */

declare global {
    interface Window {
        foo: string;
        cachedData: Object;
    }
}
var cache = {
    userRole: {},
    getUser: {},
}
if (!window.cachedData) window.cachedData = cache;

export default cache