import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button, Container, Row, Col, Jumbotron, Navbar, Nav,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { useLocation, useRouteMatch, Switch, Route } from 'react-router-dom';
import NavbarWrapper from '../Navbar/NavbarWrapper';
import Proxy from './Proxy/Proxy';
import DCProxy from './Proxy/DCProxy';
import Footer from '../Footer/Footer';
import User from './User/User';
import Generate from './Generate/Generate';
import Datacenter from './Datacenter/Datacenter'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../App.scss';

interface IProps {
  isAuthenticated: boolean;
  setIsAuthenticated: any;
  userData: any;
  setIsLoggedIn: any;
}

const Dashboard: React.FC<IProps> = (props: any) => {
  const {
    isAuthenticated, setIsAuthenticated, userData, setIsLoggedIn,
  } = props;
  const { path } = useRouteMatch();

  const location = useLocation();

  return (
    <>
      <Container className="App splash-background" fluid style={{ display: 'flex', flexDirection: 'column' }}>
        <Container>
          <NavbarWrapper isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsLoggedIn={setIsLoggedIn} />
        </Container>
        <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Row style={{ height: '100%', justifyContent: 'center' }}>
            {/* <TransitionGroup> */}
            {/* <CSSTransition key={location.key} className="fade" timeout={3000}> */}
            <Switch>



              <Route exact path={`${path}/proxies`}>

                <Proxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>
              <Route exact path={`${path}/dcproxies`}>
                <DCProxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>

              <Route exact path={`${path}/dc`}>
                <Datacenter userData={userData} />
              </Route>

              <Route exact path={`${path}/user`}>
                <User userData={userData} />
              </Route>
              <Route exact path={`${path}/generate`}>
                <Generate userData={userData} />
              </Route>
            </Switch>
            {/* </CSSTransition> */}
            {/* </TransitionGroup> */}
          </Row>

        </Container>

      </Container>
      {/* <Footer /> */}

    </>
  );
};

export default Dashboard;
