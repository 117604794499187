/* eslint-disable */
import axios from 'axios'
function fun() {
    axios.interceptors.request.use(function (config) {
        let authToken = localStorage.getItem('authToken')
        if (authToken) {
            var h = `Bearer ${authToken}`
            config.headers.common['Authorization'] = h
        }
        // console.log(config.headers)
        // Do something before request is sent
        return config;
    }, function (error) {
        // console.log('69')
        // Do something with request error
        return Promise.reject(error);
    });

}
fun()
export default fun