import React from 'react';
// import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import NavbarWrapper from '../Navbar/NavbarWrapper';
// import logo from '../../logo.svg';
import BlackHatLogo from '../../assets/blackhatlogo.svg';
import '../../App.scss';
import Features from '../Features/Features';
import Footer from '../Footer/Footer';

interface IProps {
  isAuthenticated: boolean;
  setIsAuthenticated: any;
  isLoggedIn?: boolean;
  setIsLoggedIn?: any;
}

const BlackHatSplash: React.FC<IProps> = (props: any) => {
  const {
    isAuthenticated, setIsAuthenticated, isLoggedIn, setIsLoggedIn,
  } = props;
  // const history = useHistory();

  return (
    <Container className="App splash-background" fluid>
      <Container>
        <NavbarWrapper isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Container>
      <div className="abcp">
        {/* <Row className="App-body black-hat-splash" style={{ bottom: '1.5rem', position: 'relative' }}> */}
        <Row className="splash-row">
          <Col xs={12}>
            <Row style={{ justifyContent: 'center', height: '3.5rem', marginBottom: '2rem' }}>
              <ReactTypingEffect
                text="BlackHat Proxies"
                speed={100}
                typingDelay={0}
                className="black-hat-header"
              />

            </Row>
            <Row>
              <h2 className="black-hat-paragraph">Residential Proxies that outperform every other.</h2>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <h2 className="black-hat-paragraph">Your only proxy solution.</h2>
            </Row>

          </Col>
        </Row>
        <Row className="button splash-row">
          <Image src={BlackHatLogo} className="App-logo" alt="logo" />
        </Row>
        <div>
          {/* <div>woo</div>
          <div>woo</div>
          <div>woo</div>
          <div>woo</div>
          <div>woo</div> */}
          {/* <Features /> */}
        </div>
        <div>

          <Features />
          <div className="t-mt-4 w-full" />
          <Footer />
        </div>
      </div>
      {/* </Row> */}
    </Container>
  );
};

export default BlackHatSplash;
