import React from 'react';
import {
  Row, Col, Container, Jumbotron,
} from 'react-bootstrap';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import DiscordService from '../../services/DiscordService';
import getToken from '../../helpers/getToken';
import './_footer.scss';

const Footer: React.FC = () => {
  const history = useHistory();

  function goto(href) {
    history.push(href)
  }

  function goSomething() {
    let t = getToken()
    if (t) {
      history.push('/dashboard/user')
    } else {
      goDashboard()
    }
  }
  const goDashboard = async () => {
    const onGetLoginURISuccess = (response: any) => {
      if (response.data) {
        const url = decodeURIComponent(response.data);
        window.location.href = url;
      }
    };
    const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
    await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
  }
  return (
    <div>
      <div>
        <div className="t-flex t-justify-between t-mb-6 t-text-lg">
          <div className="t-text-left">
            <p className="t-font-bold t-text-xl char t-mb-1">Sitemap</p>
            <div className="t-space-y-3">
              <p className="cp" onClick={() => goto('/shop')}>Shop</p>
              <p className="cp" onClick={goSomething}>Dashboard</p>
            </div>
            {/* <Link to="/shop"><p className="cp">Shop</p></Link> */}
            {/* <p className="cp" onClick={goSomething}>Dashboard</p> */}

          </div>
          <div className="t-text-left">
            <p className="t-font-bold t-text-xl char t-mb-1">Information</p>
            <div className="t-space-y-3">
              <p className="cp" onClick={() => goto('/terms')}>Terms of Service</p>
              {/* <Link to="/shop"><p className="cp">Terms of Service</p></Link> */}
              {/* <Link to="privacy"><p>Privacy Policy</p></Link> */}
              <p className="cp" onClick={() => goto('/privacy')}>Privacy Policy</p>
            </div>

          </div>
          {/* <div className="t-text-left t-space-y-12">
            <p className="t-font-bold t-text-xl char">Information</p>
            <Link to="/terms"><p>Terms of Service</p></Link>
            <Link to="privacy"><p>Privacy Policy</p></Link>
          </div> */}
        </div>
      </div>
      <div className="t-mb-3">

        <p className="t-text-2xl">© 2022 BlackHat Proxies</p>
      </div>
      <Row className="t-hidden" data-testid="footer" id="footer">
        <Container>
          <div>
            <p>Terms of Service</p>
            <p>Privacy Policy</p>

          </div>

          <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Col>
              <Jumbotron
                style={{ backgroundColor: 'transparent', marginBottom: '0' }}
              >
                <Row style={{ justifyContent: 'center' }}>

                  <Col xs="auto">
                    <span>
                      <FontAwesomeIcon color="#f5f5f5" icon={faTwitter} />
                    </span>
                    <span style={{ marginLeft: '0.5rem' }}>
                      <a
                        style={{ color: '#AACCFF' }}
                        href="https://twitter.com/blackhatproxies"
                      >
                        @BlackHatProxies
                      </a>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Owned and Operated by: © 2020, BlackHat Proxies</p>
                  </Col>
                </Row>

              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Footer;
