/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Nav, Row, Col, Jumbotron, Button, Card, ListGroup, ListGroupItem, Form, Modal, ButtonGroup,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import Stripe from '../../Stripe/Stripe';
import ProductService from '../../../services/ProductService';
import DiscordService from '../../../services/DiscordService';
import DiscountService from '../../../services/DiscountService';
import ProxyCheapService from '../../../services/ProxyCheapService';

interface IProps {
  userData: any;
  isAuthenticated: any;
}

const Proxy: React.FC<IProps> = (props: any) => {
  const { userData, isAuthenticated } = props;
  const proxiwareResi = 'resi';
  const proxyCheapResi = 'resi-non-expiry';
  const proxyWorksResi = 'resi-works';
  const [proxiwareProducts, setProxiwareProducts] = useState([]) as any;
  const [proxyCheapProducts, setProxyCheapProducts] = useState([]) as any;
  const [proxyWorksProducts, setProxyWorksProducts] = useState([]) as any;
  const [selectedProxyType, setSelectedProxyType] = useState(proxyWorksResi) as any;
  const [selectedProduct, setSelectedProduct] = useState() as any;
  const [productIndex, setProductIndex] = useState();
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [disableProxyCheap, setDisableProxyCheap] = useState(false);
  const [hasChosenProduct, sethasChosenProduct] = useState(true);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [show, setShow] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [discountValue, setDiscountValue] = useState('');
  const [validDiscount, setValidDiscount] = useState(false);
  const [discountBeingUsed, setDiscountBeingUsed] = useState({} as any);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  console.log('VALID DISCOUNTS: ', validDiscountCodes);

  const getProduct = async () => {
    const onGetProductSuccess = (response: any) => {
      if (response.data) {
        response.data.forEach((product) => {
          if (product.type === proxiwareResi) {
            setProxiwareProducts((oldProxiwareState: any) => [...oldProxiwareState, product]);
          } else if (product.type === proxyCheapResi) {
            setProxyCheapProducts((oldProxyCheapState: any) => [...oldProxyCheapState, product]);
          } else if (product.type == proxyWorksResi) {
            // console.log(product,6999)
            setProxyWorksProducts((old: any) => [...old, product]);
          }
        });
        setIsFetchingProducts(false);
      }
    };
    const onGetProductError = (error: any) => {
      console.log('ERROR FETCHING PRODUCTS: ', error);
    };

    await ProductService.GetProducts(onGetProductSuccess, onGetProductError);
  };

  useEffect(() => {
    getProduct();
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);

    const onGetDataSuccess = (response: any) => {
      const remainingBalance = response.data.data.balance;
      if (remainingBalance <= 1500) {
        // setSelectedProxyType(proxiwareResi);
        setDisableProxyCheap(true);
      }
    };
    const onGetDataError = (error: any) => {
      console.log('ERROR GETTING DATA: ', error.response);
    };


    ProxyCheapService.GetRemainingTotalData(onGetDataSuccess, onGetDataError);
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.type === proxiwareResi) {
        setSelectedProxyType(proxiwareResi);
      } else if (selectedProduct.type === proxyCheapResi) {
        setSelectedProxyType(proxyCheapResi);
      } else if (selectedProduct.type == proxyWorksResi) {
        setSelectedProxyType(proxyWorksResi)
      }
    }
  }, [selectedProduct]);


  const handlePurchase = async () => {
    if (isAuthenticated) {
      if (selectedProduct) {
        sethasChosenProduct(true);
        handleShow();
      } else {
        sethasChosenProduct(false);
      }
    } else {
      const onGetLoginURISuccess = (response: any) => {
        if (response.data) {
          const url = decodeURIComponent(response.data);
          window.location.href = url;
        }
      };
      const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
      await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
    }
  };

  useEffect(() => {

  }, []);


  if (isProcessingPayment) {
    return (
      <div style={{ top: '50%', position: 'relative' }}>
        <ReactTypingEffect
          text="Processing . . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>

    );
  }


  return (
    <Row>
      <Col>
        <h1 className="black-hat-header" style={{ marginTop: '5rem' }}>Residential Proxies</h1>
        <h4 style={{ fontWeight: 500 }}>All sales are final, and non-refundable.</h4>
        {/*<ButtonGroup size="lg" aria-label="Basic example">
          <Button
            className={(selectedProxyType === proxiwareResi) ? 'purchase-button' : ''}
            variant="secondary"
            onClick={() => {
              setSelectedProxyType(proxiwareResi);
            }}
          >
            60-Day Plan

          </Button>
          <Button
            className={(selectedProxyType === proxyCheapResi) ? 'purchase-button' : ''}
            variant="secondary"
            onClick={() => {
              setSelectedProxyType(proxyCheapResi);
              setSelectedProduct('');
            }}
          >
            MagicHatter Plan

          </Button>
        </ButtonGroup> */}
        {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
        <Row style={{ justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem' }}>

          {isFetchingProducts ? (
            <ReactTypingEffect
              text="Fetching Products . . ."
              speed={100}
              eraseDelay={500}
              typingDelay={0}
              className="splash-loader"
            />
          ) : (
            <>
              {selectedProxyType === proxyWorksResi ? (
                <Card className="purchase-card">
                  <Card.Img variant="top" src="" />
                  <Card.Body>
                    <Card.Title />
                    {/* creates decendant of p errors <Card.Text> */}
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <h1>MagicHatter Plan</h1>
                      <Form.Label className={hasChosenProduct ? 'text' : 'error-text'}>Please select your proxy package</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        value={(selectedProduct) ? productIndex : ''}
                        onChange={(event: any) => {
                          setSelectedProduct(proxyWorksProducts[event.target.value]);
                          setProductIndex(event.target.value);
                        }}
                        disabled={selectedProxyType === proxyCheapResi}
                      >
                        <option value="" disabled>Select Plan</option>
                        {proxyWorksProducts.map((product, index) => (
                          <option key={index} label={`${product.name} - $${validDiscount ? ((product.amount * (1 - discountBeingUsed.discount / 100)).toFixed(2)) : product.amount}`} value={index}>{product.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <ListGroup className="list-group-flush purchase-list-group">
                    <ListGroupItem>
                      <Form.Label>
                        {validDiscount && (
                          'Discount has been applied!'
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={discountValue}
                        onChange={(event: any) => {
                          setDiscountValue(event.target.value);
                        }}
                        onKeyPress={(event: any) => {
                          if (event.charCode === 13) {
                            const isValidCode = validDiscountCodes.find((discountCode) => {
                              if (discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                                setDiscountBeingUsed(discountCode);
                                return discountCode;
                              }
                            });
                            if (isValidCode) {
                              setValidDiscount(true);
                            } else {
                              setValidDiscount(false);
                            }
                          }
                        }}
                        onBlur={() => {
                          const isValidCode = validDiscountCodes.find((discountCode) => {
                            if (discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                              setDiscountBeingUsed(discountCode);
                              return discountCode;
                            }
                          });
                          if (isValidCode) {
                            setValidDiscount(true);
                          } else {
                            setValidDiscount(false);
                          }
                        }}
                        placeholder="Enter discount code here"
                      />

                    </ListGroupItem>
                    <ListGroupItem>NO EXPIRATION</ListGroupItem>
                    <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                    <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                    <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem>
                  </ListGroup>
                  <Card.Body>
                    <Button
                      className="purchase-button"
                      onClick={handlePurchase}
                    >
                      PURCHASE
                    </Button>
                  </Card.Body>
                </Card>
              ) : <Card>{selectedProxyType}</Card>
              }


            </>
          )}

        </Row>
        <Modal show={show} centered onHide={handleClose} className="proxy-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              {hasAgreedToTerms ? (
                <>
                  <p>PURCHASE NOW</p>
                  <p style={{ fontSize: '1rem' }}>{selectedProduct.description}</p>
                </>
              ) : (<p>TERMS OF SALE</p>)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
            {hasAgreedToTerms ? <Stripe selectedProduct={selectedProduct} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsed.discount} discountValue={validDiscount ? discountValue : ''} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
          </Modal.Body>
          <Modal.Footer>
            {hasAgreedToTerms ? (
              <Button
                variant="primary"
                className="purchase-button"
                onClick={() => {
                  handleClose();
                  setHasAgreedToTerms(false);
                }}
              >
                CANCEL
              </Button>
            ) : (
              <Button variant="primary" className="purchase-button" onClick={() => { setHasAgreedToTerms(true); }}>
                I AGREE
              </Button>
            )}
          </Modal.Footer>
        </Modal>


        <Nav.Link as={NavLink} exact to="/dashboard/dcproxies" className="black-hat-header-mini" style={{ marginBottom: '2rem', fontSize: '2rem' }}>Buy ISP?</Nav.Link>
        {/* <h6 className="black-hat-header-mini" style={{marginBottom: '2rem'}}><a href="/dashboard/dcproxies">Buy Datacenter instead?</a></h6> */}

      </Col>
    </Row>
  );
};

export default Proxy;
