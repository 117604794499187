/* eslint-disable */
import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';
export { }
declare global {
  interface Window {
    foo: string;
    cachedData: Object;
  }
}
let getIt = () => {

  return window.foo;

}
export default class SubWorksService {
  static async getPools() {
    if (getIt()) return getIt()
    console.log(`getting sub pools from api`)
    let url = getAPIPath('subworks/pools')
    let r = await axios({ url });
    window.foo = r.data
    return r.data
  }
  static async getRemaining() {
    let url = getAPIPath('subworks/seller')
    let r = await axios({ url })
    return r.data
  }

}
