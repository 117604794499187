import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class UserService {
  static async GetAll(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('users/get/'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }
}
