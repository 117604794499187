
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button, Container, Row, Col, Jumbotron, Navbar, Nav,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import NavbarWrapper from '../Navbar/NavbarWrapper';
import '../../App.scss';
import Shop from './Shop'

interface IProps {
    isAuthenticated: boolean;
    setIsAuthenticated: any;
    userData: any;
    isLoggedIn: any;
    setIsLoggedIn: any;
}

const ShopWrapper: React.FC<IProps> = (props: any) => {
    const {
        isAuthenticated, setIsAuthenticated, userData, setIsLoggedIn, isLoggedIn
    } = props;
    const { path } = useRouteMatch();

    const startupDC = async function () {
        // console.log(`running startup dc`)
    }
    useEffect(() => {
        startupDC()
    }, []);

    return (
        <>
            <Container className="App splash-background" fluid style={{ display: 'flex', flexDirection: 'column' }}>
                <Container>
                    <NavbarWrapper isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsLoggedIn={setIsLoggedIn} />
                </Container>
                <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Row style={{ height: '100%', justifyContent: 'center' }}>
                        <Shop userData={userData} isAuthenticated={isAuthenticated} isLoggedIn={isLoggedIn}></Shop>

                        {/*<Switch>
              <Route exact path={`${path}/proxies`}>

                <Proxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>
              <Route exact path={`${path}/dcproxies`}>
                <DCProxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>
              <Route exact path={`${path}/dc`}>
              <Datacenter userData={userData}/>
              </Route>
              <Route exact path={`${path}/user`}>
                <User userData={userData} />
              </Route>
              <Route exact path={`${path}/generate`}>
                <Generate userData={userData} />
              </Route>
            </Switch> */}
                    </Row>

                </Container>

            </Container>
            {/* <Footer /> */}

        </>
    );
};

export default ShopWrapper;
