import React, { useState, useEffect } from 'react';
import {
  Row, Col, Container, Button, Form, Card, ListGroup, ListGroupItem, Modal, ButtonGroup,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ProductService from '../../services/ProductService';
import DiscountService from '../../services/DiscountService';
import PasswordService from '../../services/PasswordService';
import Stripe from '../Stripe/DCStripe';
import PaypalButton from '../Paypal/paypalButton';
import NavbarWrapper from '../Navbar/NavbarWrapper';
import DCService from '../../services/DCService'
import DiscordService from '../../services/DiscordService'

interface IProps {
  userData: any;
  isLoggedIn: boolean;
  setIsLoggedIn: any;
  setIsAuthenticated: any;
  setNewMemberHasPurchased: any;
}
const PasswordPage: React.FC<IProps> = (props: any) => {
  const {
    userData, isLoggedIn, setIsLoggedIn, setIsAuthenticated, setNewMemberHasPurchased,
  } = props;

  const proxiwareResi = 'resi';
  const proxyCheapResi = 'resi-non-expiry';

  // const passwordRegex = /tidalrents|cooking20/gmi;
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [show, setShow] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [hasChosenProduct, sethasChosenProduct] = useState(true);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [discountValue, setDiscountValue] = useState('');
  const [validDiscount, setValidDiscount] = useState(false);
  const [discountBeingUsed, setDiscountBeingUsed] = useState({} as any);


  const [validPasswords, setValidPasswords] = useState([] as any);
  const [validPassword, setValidPassword] = useState('');



  const [planQty, setPlanQty] = useState(25)
  const [timespan, setTimespan] = useState(null)
  const [qtyPlans, setPlans] = useState({})
  const [isInStock, setisInstock] = useState(true)
  const [stockData, setStockData] = useState({})

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const getQueryPw = () => {
    var urlParams = new URLSearchParams(window.location.search);
    let pwVal = urlParams.get('x')
    return pwVal || ''
  }
  function shouldShowInput() {
    let x = getQueryPw()
    if (isValidPassword) return false
    if (!isValidPassword && !x) return true
    if (!isValidPassword && x && validPasswords.length == 0) {
      // console.log(`should not show pw input`,66)
      return false
    }
    return true
  }

  const nameFromId = id => {
    let x = validPasswords.find(x => x._id == id)
    if (!x) return x
    return x.name
  }

  const handlePassword = (event: any, data: any) => {
    console.log(`handlePassword called`, event)
    console.log(validPasswords.length);
    if (event.charCode === 13) {
      // if (event.target.value.match(passwordRegex)) {
      //   setIsValidPassword(true);
      // } else {
      //   setIsValidPassword(false);
      // }
      if (!data) data = validPasswords
      data.find((password) => {
        const passwordRegex = new RegExp(password.name, 'gmi');
        console.log(password.name, event.target.value)
        if (event.target.value.match(passwordRegex)) {
          setIsValidPassword(true);
          setValidPassword(password._id);
          return password;
        }
        setIsValidPassword(false);
      });
    }
  };


  function getDiscountedPrice(price, percent) {
    if (!percent) return price
    var p = (price * (1 - percent / 100)).toFixed(2)
    return p
  }
  function createLabel(key) {
    var planPrice = qtyPlans[key]
    var price = validDiscount ? getDiscountedPrice(planPrice, discountBeingUsed.discount) : planPrice
    return `${key} - $${price}`
  }
  function getPlanKeys() {
    return Object.keys(qtyPlans)
  }
  function getSelectedProduct() {
    let planPrice = qtyPlans[planQty]
    let p = {
      count: planQty,
      amount: planPrice,
      type: 'dc',
      // amount:getDiscountedPrice(planPrice,discountBeingUsed.discount),
      name: 'Datacenter Proxies',
    }
    console.log(p)
    return p
  }

  const getProduct = async () => {
    let stockData = await DCService.getPlans();
    console.log(`got stock data:`, stockData)
    setStockData(stockData);
    let { inStock, reserved, total, plans } = stockData
    setisInstock(inStock)
    setPlans(plans)
    setIsFetchingProducts(false);
  }

  useEffect(() => {
    getProduct();
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);

    const onGetValidPasswordSuccess = (response: any) => {
      setValidPasswords([...response.data]);
      let pwVal = getQueryPw()
      if (pwVal) {
        console.log(`pw startup fn with id ${pwVal}`);
        let x = { charCode: 13, target: { value: pwVal } }
        handlePassword(x, response.data)
      }

    };
    const onGetValidPasswordError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    PasswordService.GetValid(onGetValidPasswordSuccess, onGetValidPasswordError);
  }, []);

  const handlePurchase = async () => {
    if (isLoggedIn) {
      handleShow()
    } else {
      const onGetLoginURISuccess = (response: any) => {
        if (response.data) {
          const url = decodeURIComponent(response.data);
          window.location.href = url;
        }
      };
      const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
      await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
    }
  };



  return (
    <Container className="App splash-background" fluid>
      {isProcessingPayment ? (
        <div style={{ top: '50%', position: 'relative' }}>
          <ReactTypingEffect
            text="Processing . . ."
            speed={100}
            eraseDelay={500}
            typingDelay={0}
            className="splash-loader"
          />
        </div>
      ) : (
          <>
            <Container>
              <NavbarWrapper showDCPw={getQueryPw() || ''} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsAuthenticated={setIsAuthenticated} />
            </Container>
            <Row noGutters className="App-body black-hat-splash" style={{ bottom: '1.5rem', position: 'relative' }}>
              <Row className="splash-row">

                <Col xs={12}>
                  {shouldShowInput() ? (
                    <>
                      <Row style={{ justifyContent: 'center', height: '3.5rem', marginBottom: '2rem' }}>
                        <ReactTypingEffect
                          text="Password Page"
                          speed={100}
                          typingDelay={0}
                          className="black-hat-header"
                        />
                      </Row>
                      <Row>
                        <Col xs="auto">
                          <h2 className="black-hat-paragraph">Please enter the password in order to purchase a proxy plan.</h2>

                        </Col>
                      </Row>
                      <Row style={{ justifyContent: 'center', marginTop: '5rem' }}>
                        <Col xs={4}>
                          <Form.Control placeholder="" onKeyPress={(event: any) => {
                            handlePassword(event, null)
                          }
                          }
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                      <>
                        <Row>
                          <Col>
                            <h1 className="black-hat-header" style={{ marginTop: '5rem' }}>Datacenter Proxies</h1>
                            <h4 style={{ fontWeight: 500 }}>All sales are final, and non-refundable.</h4>
                            {/*
                        <ButtonGroup size="lg" aria-label="Basic example">
                          <Button
                            className={(selectedProxyType === proxiwareResi) ? 'purchase-button' : ''}
                            variant="secondary"
                            onClick={() => {
                              setSelectedProxyType(proxiwareResi);
                              setSelectedProduct('');
                            }}
                          >
                            60-Day

                          </Button>
                          <Button
                            className={(selectedProxyType === proxyCheapResi) ? 'purchase-button' : ''}
                            variant="secondary"
                            onClick={() => {
                              setSelectedProxyType(proxyCheapResi);
                              setSelectedProduct('');
                            }}
                          >
                            Non-Expiry Plan

                          </Button>
                        </ButtonGroup> */}
                            {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
                            <Form>
                              <Row style={{ justifyContent: 'center', marginTop: '2rem' }}>

                                {isFetchingProducts ? (
                                  <ReactTypingEffect
                                    text="Fetching Products . . ."
                                    speed={100}
                                    eraseDelay={500}
                                    typingDelay={0}
                                    className="splash-loader"
                                  />
                                ) : (
                                    <>
                                      <Card className="purchase-card">
                                        <Card.Img variant="top" src="" />
                                        <Card.Body>
                                          <Card.Title />
                                          <Form.Group controlId="exampleForm.ControlSelect2">
                                            <h1>30 Day Plan</h1>
                                            {/*<Form.Label className={hasChosenProduct ? 'text' : 'error-text'}>Please select your proxy package</Form.Label> */}
                                            <Form.Label className={true ? 'text' : 'error-text'}>Please select your proxy package.</Form.Label>
                                            <Form.Control
                                              as="select"
                                              value={planQty || ''}
                                              onChange={(event: any) => {
                                                console.log(`form control changed`)
                                                console.log(event.target.value)
                                                setPlanQty(event.target.value)
                                              }}
                                            >
                                              <option value="" disabled>Select Quantity</option>
                                              {getPlanKeys().map((key, index) => (
                                                <option key={index} label={createLabel(key)} value={key}>{key}</option>
                                              ))}

                                            </Form.Control>
                                          </Form.Group>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush purchase-list-group">
                                          <ListGroupItem>
                                            <Form.Label>
                                              {validDiscount && (
                                                'Discount has been applied!'
                                              )}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={discountValue}
                                              onChange={(event: any) => {
                                                setDiscountValue(event.target.value);
                                              }}
                                              onKeyPress={(event: any) => {
                                                if (event.charCode === 13) {
                                                  event.preventDefault();
                                                  const isValidCode = validDiscountCodes.find((discountCode) => {
                                                    let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                    if (isDc && discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                                                      setDiscountBeingUsed(discountCode);
                                                      return discountCode;
                                                    }
                                                  });
                                                  if (isValidCode) {
                                                    setValidDiscount(true);
                                                  } else {
                                                    setValidDiscount(false);
                                                  }
                                                }
                                              }}
                                              onBlur={() => {
                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                  let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                  if (isDc && discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                                                    setDiscountBeingUsed(discountCode);
                                                    return discountCode;
                                                  }
                                                });
                                                if (isValidCode) {
                                                  setValidDiscount(true);
                                                } else {
                                                  setValidDiscount(false);
                                                }
                                              }}
                                              placeholder="Enter discount code here"
                                            />

                                          </ListGroupItem>
                                          <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                          {/*<ListGroupItem>STATIC AND ROTATING PROXIES</ListGroupItem>*/}
                                          <ListGroupItem>FOOTSITES, SUPREME, SHOPIFY, YEEZYSUPPLY</ListGroupItem>
                                        </ListGroup>
                                        <Card.Body>
                                          <Button
                                            className="purchase-button"
                                            onClick={handlePurchase}
                                            disabled={!isInStock}
                                          >
                                            {!!isInStock ? "PURCHASE" : "SOLD OUT"}
                                          </Button>
                                        </Card.Body>
                                      </Card>
                                    </>

                                  )}

                              </Row>
                              <Modal show={show} centered onHide={handleClose} className="proxy-modal">
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    {hasAgreedToTerms ? (<p>PURCHASE NOW</p>) : (<p>TERMS OF SALE</p>)}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {hasAgreedToTerms ? <Stripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isLoggedIn} discountAmount={discountBeingUsed.discount} discountValue={discountValue} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                                </Modal.Body>
                                <Modal.Footer>
                                  {hasAgreedToTerms ? (
                                    <Button
                                      variant="primary"
                                      className="purchase-button"
                                      onClick={() => {
                                        handleClose();
                                        setHasAgreedToTerms(false);
                                      }}
                                    >
                                      CANCEL
                                    </Button>
                                  ) : (
                                      <Button
                                        variant="primary"
                                        className="purchase-button"
                                        onClick={() => {
                                          setHasAgreedToTerms(true);
                                          PasswordService.DecreaseUses(validPassword, () => { }, () => { });
                                        }}
                                      >
                                        I AGREE
                                      </Button>
                                    )}
                                </Modal.Footer>
                              </Modal>
                            </Form>
                          </Col>
                        </Row>
                      </>
                    )}


                </Col>
              </Row>
            </Row>
          </>
        )}

    </Container>
  );
};

export default PasswordPage;
