/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import {
    Nav, Row, Col, Jumbotron, Button, Card, ListGroup, ListGroupItem, Form, Modal, ButtonGroup,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import PasswordService from '../../services/PasswordService';
import DCStripe from '../Stripe/DCStripe';
import SStripe from '../Stripe/SStripe';
import Stripe from '../Stripe/Stripe';
import ProductService from '../../services/ProductService';
import DiscordService from '../../services/DiscordService';
import DiscountService from '../../services/DiscountService';
import DCService from '../../services/DCService';
import ShService from '../../services/ShService';
import './_shop.scss';
import getToken from '../../helpers/getToken'
import { isJsxClosingElement } from 'typescript';

interface IProps {
    userData: any;
    isLoggedIn: any;
    isAuthenticated: any;
}

const Proxy: React.FC<IProps> = (props: any) => {
    const { userData, isAuthenticated, isLoggedIn } = props;
    const proxyWorksResi = 'resi-works';
    // const [selectedProduct, setSelectedProduct] = useState() as any;
    // const [productIndex, setProductIndex] = useState();
    const [planQty, setPlanQty] = useState(25)
    const [planQtyS, setPlanQtyS] = useState(25)
    const [selectedProduct, setSelectedProduct] = useState({}) as any;
    const [timespan, setTimespan] = useState(null)
    const [productIndex, setProductIndex] = useState();
    const [qtyPlans, setPlans] = useState({})
    const [qtyPlansS, setPlansS] = useState({})
    const [selectedProxyType, setSelectedProxyType] = useState(proxyWorksResi) as any;
    const [stockData, setStockData] = useState({})
    const [stockDataS, setStockDataS] = useState({})
    const [isFetchingProducts, setIsFetchingProducts] = useState(true);
    const [isInStock, setisInstock] = useState(true)
    const [isInStockS, setisInstockS] = useState(true)
    const [isFetchingProductsResi, setIsFetchingProductsResi] = useState(true);
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    const [showDC, setShowDC] = useState(false);
    const [showShopify, setShowShopify] = useState(false);
    const [showResi, setShowResi] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(true);
    const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
    const [discountValueResi, setDiscountValueResi] = useState('');
    const [discountValueDC, setDiscountValueDC] = useState('');
    const [validDiscountResi, setValidDiscountResi] = useState(false);
    const [validDiscountDC, setValidDiscountDC] = useState(false);
    const [discountBeingUsedResi, setDiscountBeingUsedResi] = useState({} as any);
    const [discountBeingUsedDC, setDiscountBeingUsedDC] = useState({} as any);
    const [proxyWorksProducts, setProxyWorksProducts] = useState([]) as any;
    const [proxyType, setProxyType] = useState('resi') as any;
    const [needsPassword, setNeedsPassword] = useState(true);
    const [validPasswords, setValidPasswords] = useState([] as any);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [validPassword, setValidPassword] = useState('');

    let baseDescription = 'Black Hat Datacenter Proxies - Non Refundable. Digital Goods Delivered Upon Payment'
    // let resiDescription = ''

    const handleCloseDC = () => setShowDC(false);
    const handleShowDC = () => setShowDC(true);

    const handleCloseS = () => setShowShopify(false);
    const handleShowS = () => setShowShopify(true);

    const handlePassword = (event: any, data: any) => {
        console.log('handling password')

        if (event.charCode === 13) {
            console.log('handling password 13')
            if (!data) data = validPasswords
            // if (event.target.value.match(passwordRegex)) {
            //   setIsValidPassword(true);
            // } else {
            //   setIsValidPassword(false);
            // }
            data.find((password) => {
                const passwordRegex = new RegExp(password.name, 'gmi');
                if (event.target.value.match(passwordRegex)) {
                    console.log('valid password entered.:)')
                    setIsValidPassword(true);
                    setNeedsPassword(false)
                    setValidPassword(password._id);
                    return password;
                }
                setIsValidPassword(false);
            });
        }
    };
    const getQueryPw = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let pwVal = urlParams.get('x')
        return pwVal || ''
    }
    const handleCloseResi = () => setShowResi(false);
    const handleShowResi = () => setShowResi(true);

    function getDiscountedPrice(price, percent) {
        if (!percent) return price
        var p = (price * (1 - percent / 100)).toFixed(2)
        return p
    }
    function createLabel(key) {
        var planPrice = qtyPlans[key]
        var price = validDiscountDC ? getDiscountedPrice(planPrice, discountBeingUsedDC.discount) : planPrice
        return `${key} - $${price}`
    }

    function createLabelS(key) {
        var planPrice = qtyPlansS[key]
        var price = validDiscountDC ? getDiscountedPrice(planPrice, discountBeingUsedDC.discount) : planPrice
        return `${key} - $${price}`
    }
    function getPlanKeys() {
        return Object.keys(qtyPlans)
    }

    function getPlanKeysS() {
        return Object.keys(qtyPlansS)
    }
    function getSelectedProduct() {
        let planPrice = qtyPlans[planQty]
        let p = {
            count: planQty,
            amount: planPrice,
            type: 'dc',
            // amount:getDiscountedPrice(planPrice,discountBeingUsed.discount),
            name: 'Datacenter Proxies',
        }
        console.log(p)
        return p
    }

    function getSelectedProductS() {
        let planPrice = qtyPlansS[planQtyS]
        let p = {
            count: planQtyS,
            amount: planPrice,
            type: 'dc',
            // amount:getDiscountedPrice(planPrice,discountBeingUsed.discount),
            name: 'Datacenter Proxies',
        }
        console.log(p)
        return p
    }



    const getProductDC = async () => {
        let stockData = await DCService.getPlans();
        console.log(`got stock data:`, stockData)
        setStockData(stockData);
        let { inStock, reserved, total, plans } = stockData
        setisInstock(inStock)
        setPlans(plans)
        setIsFetchingProducts(false);
    }

    const getProductShopify = async () => {
        let stockData = await ShService.getPlans();
        console.log(`got stock data:`, stockData)
        setStockDataS(stockData);
        let { inStock, reserved, total, plans } = stockData
        setisInstockS(inStock)
        setPlansS(plans)
        setIsFetchingProducts(false);
    }
    const getProductResi = async () => {
        const onGetProductSuccess = (response: any) => {
            if (response.data) {
                console.log(`fetched resi products...`, response.data)
                response.data.forEach((product) => {
                    if (product.type == proxyWorksResi) {
                        // console.log(product,6999)
                        setProxyWorksProducts((old: any) => [...old, product]);
                    }
                });
                setIsFetchingProductsResi(false);
            }
        };
        const onGetProductError = (error: any) => {
            console.log('ERROR FETCHING PRODUCTS: ', error);
        };

        await ProductService.GetProducts(onGetProductSuccess, onGetProductError);
    };


    useEffect(() => {
        getProductResi();
        getProductDC()
        getProductShopify()
        const onGetValidDiscountSuccess = (response: any) => {
            setValidDiscountCodes([...response.data]);
        };
        const onGetValidDiscountError = (error: any) => {
            console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
        };
        DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);
        const onGetValidPasswordSuccess = (response: any) => {
            setValidPasswords([...response.data]);
            let pwVal = getQueryPw()
            if (pwVal) {
                console.log(`pw startup fn with id ${pwVal}`);
                let x = { charCode: 13, target: { value: pwVal } }
                handlePassword(x, response.data)
            }
        };
        const onGetValidPasswordError = (error: any) => {
            console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
        };
        PasswordService.GetValid(onGetValidPasswordSuccess, onGetValidPasswordError);
        // if(userData.role)
    }, []);



    const handlePurchaseResi = async () => {
        // alert('using resi');
        if (JSON.stringify(selectedProduct) == '{}') {
            return
        }
        if (isAuthenticated) {
            setNeedsPassword(false)
            // alert('wht ze fucj')
            handleShowResi()
        } else {
            console.log(userData, 555)
            const onGetLoginURISuccess = (response: any) => {
                if (response.data) {
                    const url = decodeURIComponent(response.data);
                    window.location.href = url;
                }
            };
            const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
            let tok = getToken()
            if (!tok) {
                return await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
            }
            setNeedsPassword(true)
            handleShowResi()
        }
    };

    const handlePurchaseS = async () => {
        // alert('using dc');
        if (isAuthenticated) {
            setNeedsPassword(false)
            handleShowS()
        } else {
            const onGetLoginURISuccess = (response: any) => {
                if (response.data) {
                    const url = decodeURIComponent(response.data);
                    window.location.href = url;
                }
            };
            const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
            let tok = getToken()
            if (!tok) {
                return await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
            }
            setNeedsPassword(true)
            handleShowS()
        }
    };
    const handlePurchaseDC = async () => {
        // alert('using dc');
        if (isAuthenticated) {
            setNeedsPassword(false)
            handleShowDC()
        } else {
            const onGetLoginURISuccess = (response: any) => {
                if (response.data) {
                    const url = decodeURIComponent(response.data);
                    window.location.href = url;
                }
            };
            const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
            let tok = getToken()
            if (!tok) {
                return await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
            }
            setNeedsPassword(true)
            handleShowDC()
        }
    };

    useEffect(() => {

    }, []);


    if (isProcessingPayment) {
        return (
            <div style={{ top: '50%', position: 'relative' }}>
                <ReactTypingEffect
                    text="Processing . . ."
                    speed={100}
                    eraseDelay={500}
                    typingDelay={0}
                    className="splash-loader"
                />
            </div>

        );
    }


    return (
        <div>
            <div>
                <div className="t-text-center">
                    <div className="t-space-x-4">
                        <button className={`null snd switch t-text-xl ${proxyType == 'resi' ? 'hasBg' : 't-bg-gray-700'}`}

                            onClick={() => {
                                setProxyType('resi')

                            }}
                        >
                            Residential
       </button>

                        <button className={`null switch t-text-xl ${proxyType == 'dc' ? 'hasBg' : 't-bg-gray-700'}`}
                            onClick={() => {
                                setProxyType('dc')

                            }}
                        >
                            ISP
       </button>
                        <div className="lg:t-hidden">
                            <button className={`null switch t-text-xl ${proxyType == 'shopify' ? 'hasBg' : 't-bg-gray-700'}`}
                                onClick={() => {
                                    setProxyType('shopify')

                                }}
                            >
                                Shopify
       </button>
                        </div>
                    </div>
                </div>
                {/* <h1 className="black-hat-header" style={{ marginTop: '5rem' }}>Shop Proxies</h1>
                < h4 className="mt-0" style={{ fontWeight: 500 }}>All sales are final, and non-refundable.</h4> */}
                {/*<ButtonGroup size="lg" aria-label="Basic example">
          <Button
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`some button clcked`);
              // setSelectedProxyType(proxiwareResi);
            }}
          >
            60-Day Plan

          </Button>
          <Button
            disabled={false}
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`other button clicked non exp`)
            }}
          >
            Non-Expiry Plan

          </Button>
        </ButtonGroup> */}
                {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
                <div style={{ justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem' }}>

                    {isFetchingProducts ? (
                        <ReactTypingEffect
                            text="Fetching Products . . ."
                            speed={100}
                            eraseDelay={500}
                            typingDelay={0}
                            className="splash-loader"
                        />
                    ) : (
                        // <div className="t-text-center t-flex t-justify-center">
                        <div>
                            <div className="lg:t-hidden">
                                {proxyType == 'dc' ? (
                                    <div>
                                        <div className="text-2xl t-flex t-justify-center">
                                            <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
                                                {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

                                                {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
                                                <div className="t-bg-transparent">

                                                    <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                                                        <h1>Release Madhatter ISP Plan</h1>
                                                        <Form.Label className='text t-mx-1 t-mt-6'>Please select your proxy package</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            defaultValue=""
                                                            value={(selectedProduct) ? productIndex : ''}
                                                            onChange={(event: any) => {
                                                                setPlanQty(event.target.value)
                                                                // setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                                // setProductIndex(event.target.value);
                                                            }}
                                                            disabled={selectedProxyType === 404}
                                                        >
                                                            <option value="" disabled>Select Quantity</option>
                                                            {getPlanKeys().map((key, index) => (
                                                                <option key={index} label={createLabel(key)} value={key}>{key}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div>
                                                    {/* <ListGroup className="list-group-flush purchase-list-group"> */}
                                                    {/* <ListGroupItem> */}
                                                    {validDiscountDC && (
                                                        <Form.Label>
                                                            {validDiscountDC && (
                                                                'Discount has been applied!'
                                                            )}
                                                        </Form.Label>
                                                    )}
                                                    <Form.Control
                                                        type="text"
                                                        value={discountValueDC}
                                                        onChange={(event: any) => {
                                                            setDiscountValueDC(event.target.value);
                                                        }}
                                                        onKeyPress={(event: any) => {
                                                            if (event.charCode === 13) {
                                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                    let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                    if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                        setDiscountBeingUsedDC(discountCode);
                                                                        return discountCode;
                                                                    }
                                                                });
                                                                if (isValidCode) {
                                                                    setValidDiscountDC(true);
                                                                } else {
                                                                    setValidDiscountDC(false);
                                                                }
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                    setDiscountBeingUsedDC(discountCode);
                                                                    return discountCode;
                                                                }
                                                            });
                                                            if (isValidCode) {
                                                                setValidDiscountDC(true);
                                                            } else {
                                                                setValidDiscountDC(false);
                                                            }
                                                        }}
                                                        placeholder="Enter discount code here"
                                                    />

                                                    {/* </ListGroupItem> */}
                                                    {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
                                                    {/* </ListGroup> */}
                                                </div>
                                                {/* </div> */}
                                                <div className="ffont-size t-text-lg t-space-y-3 grow">
                                                    {/* <p className="gray-border">30 Day Plan</p> */}
                                                    <p className="gray-border">30 Day Plan</p>
                                                    <p className="gray-border">User:Pass Auth</p>
                                                    {/* <p className="gray-border">Static & Rotating Proxies</p> */}
                                                    {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
                                                    <p className="gray-border">Footsites (not guaranteed), Yeezy Supply, Supreme, Shopify, & More</p>
                                                    <p className="gray-border">Hosted in Ashburn, VA</p>
                                                    {/* <p className="t-text-transparent">A</p> */}
                                                </div>
                                                <div>
                                                    <Button
                                                        className="t-w-full t-text-xl purchasecoke"
                                                        disabled={!isInStock}
                                                        onClick={handlePurchaseDC}

                                                    >
                                                        {!!isInStock ? "Purchase" : "SOLD OUT"}
                                                    </Button>
                                                </div>
                                                {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                    </div>

                                )}
                            </div>



                            {proxyType != 'dc' && proxyType != 'shopify' ? (

                                <div>

                                    <div>
                                        <div>
                                            <div className="text-2xl t-flex t-justify-center">
                                                <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
                                                    {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

                                                    {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
                                                    <div className="t-bg-transparent">

                                                        <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                                                            <h1>MagicHatter Plan</h1>
                                                            <Form.Label className='text t-mt-6'>Please select your proxy package</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                defaultValue=""
                                                                value={(selectedProduct) ? productIndex : ''}
                                                                onChange={(event: any) => {
                                                                    setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                                    setProductIndex(event.target.value);
                                                                }}
                                                                disabled={selectedProxyType === 404}
                                                            >
                                                                <option value="" disabled>Select Plan</option>
                                                                {proxyWorksProducts.map((product, index) => (
                                                                    <option key={index} label={`${product.name} - $${validDiscountResi ? ((product.amount * (1 - discountBeingUsedResi.discount / 100)).toFixed(2)) : product.amount}`} value={index}>{product.name}</option>
                                                                ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </div>
                                                    <div>
                                                        {/* <ListGroup className="list-group-flush purchase-list-group"> */}
                                                        {/* <ListGroupItem> */}
                                                        {validDiscountResi && (
                                                            <Form.Label>
                                                                {validDiscountResi && (
                                                                    'Discount has been applied!'
                                                                )}
                                                            </Form.Label>
                                                        )}
                                                        <Form.Control
                                                            type="text"
                                                            value={discountValueResi}
                                                            onChange={(event: any) => {
                                                                setDiscountValueResi(event.target.value);
                                                            }}
                                                            onKeyPress={(event: any) => {
                                                                if (event.charCode === 13) {
                                                                    const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                        if (discountCode.name.toLowerCase() === discountValueResi.toLowerCase()) {
                                                                            setDiscountBeingUsedResi(discountCode);
                                                                            return discountCode;
                                                                        }
                                                                    });
                                                                    if (isValidCode) {
                                                                        setValidDiscountResi(true);
                                                                    } else {
                                                                        setValidDiscountResi(false);
                                                                    }
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                    if (discountCode.name.toLowerCase() === discountValueResi.toLowerCase()) {
                                                                        setDiscountBeingUsedResi(discountCode);
                                                                        return discountCode;
                                                                    }
                                                                });
                                                                if (isValidCode) {
                                                                    setValidDiscountResi(true);
                                                                } else {
                                                                    setValidDiscountResi(false);
                                                                }
                                                            }}
                                                            placeholder="Enter discount code here"
                                                        />

                                                        {/* </ListGroupItem> */}
                                                        {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
                                                        {/* </ListGroup> */}
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="ffont-size t-text-lg t-space-y-3">
                                                        <p className="gray-border">No Expiration</p>
                                                        <p className="gray-border">User:Pass Auth</p>
                                                        <p className="gray-border">Static & Rotating Proxies</p>
                                                        {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
                                                        <p className="gray-border">Footsites, Yeezy Supply, Supreme, Shopify, & More</p>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            className="purchase-button t-w-full t-font-bold t-text-xl t-mt-6"
                                                            onClick={handlePurchaseResi}
                                                        >
                                                            Purchase
                    </Button>
                                                    </div>
                                                    {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                (
                                    <div>
                                    </div>
                                )
                            }
                            <div className="lg:t-hidden">
                                {proxyType == 'shopify' && (

                                    <div>
                                        <div className="text-2xl t-flex t-justify-center">
                                            <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
                                                {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

                                                {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
                                                <div className="t-bg-transparent">

                                                    <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                                                        <h1>Shopify ISP Plan</h1>
                                                        <Form.Label className='text t-mx-1 t-mt-6'>Please select your proxy package</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            defaultValue=""
                                                            value={(selectedProduct) ? productIndex : ''}
                                                            onChange={(event: any) => {
                                                                setPlanQtyS(event.target.value)
                                                                // setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                                // setProductIndex(event.target.value);
                                                            }}
                                                            disabled={selectedProxyType === 404}
                                                        >
                                                            <option value="" disabled>Select Quantity</option>
                                                            {getPlanKeysS().map((key, index) => (
                                                                <option key={index} label={createLabelS(key)} value={key}>{key}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div>
                                                    {/* <ListGroup className="list-group-flush purchase-list-group"> */}
                                                    {/* <ListGroupItem> */}
                                                    {validDiscountDC && (
                                                        <Form.Label>
                                                            {validDiscountDC && (
                                                                'Discount has been applied!'
                                                            )}
                                                        </Form.Label>
                                                    )}
                                                    <Form.Control
                                                        type="text"
                                                        value={discountValueDC}
                                                        onChange={(event: any) => {
                                                            setDiscountValueDC(event.target.value);
                                                        }}
                                                        onKeyPress={(event: any) => {
                                                            if (event.charCode === 13) {
                                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                    let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                    if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                        setDiscountBeingUsedDC(discountCode);
                                                                        return discountCode;
                                                                    }
                                                                });
                                                                if (isValidCode) {
                                                                    setValidDiscountDC(true);
                                                                } else {
                                                                    setValidDiscountDC(false);
                                                                }
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                    setDiscountBeingUsedDC(discountCode);
                                                                    return discountCode;
                                                                }
                                                            });
                                                            if (isValidCode) {
                                                                setValidDiscountDC(true);
                                                            } else {
                                                                setValidDiscountDC(false);
                                                            }
                                                        }}
                                                        placeholder="Enter discount code here"
                                                    />

                                                    {/* </ListGroupItem> */}
                                                    {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
                                                    {/* </ListGroup> */}
                                                </div>
                                                {/* </div> */}
                                                <div className="ffont-size t-text-lg t-space-y-3 grow">
                                                    {/* <p className="gray-border">30 Day Plan</p> */}
                                                    <p className="gray-border">30 Day Plan</p>
                                                    <p className="gray-border">User:Pass Auth</p>
                                                    {/* <p className="gray-border">Static & Rotating Proxies</p> */}
                                                    {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
                                                    <p className="gray-border">Footsites (not guaranteed), Yeezy Supply, Supreme, Shopify, & More</p>
                                                    <p className="gray-border">Hosted in Ashburn, VA</p>
                                                    {/* <p className="t-text-transparent">A</p> */}
                                                </div>
                                                <div>
                                                    <Button
                                                        className="t-w-full t-text-xl purchasecoke"
                                                        disabled={!isInStockS}
                                                        onClick={handlePurchaseS}

                                                    >
                                                        {!!isInStockS ? "Purchase" : "SOLD OUT"}
                                                    </Button>
                                                </div>
                                                {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-between t-space-x-8 flex-row t-hidden lg:t-flex">

                                {proxyType == 'dc' && (
                                    <div>
                                        <div className="text-2xl t-flex t-justify-center">
                                            <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
                                                {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

                                                {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
                                                <div className="t-bg-transparent">

                                                    <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                                                        <h1>Madhatter ISP Plan</h1>
                                                        <h2>Release Plan</h2>
                                                        <Form.Label className='text t-mx-1 t-mt-6'>Please select your proxy package</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            defaultValue=""
                                                            value={(selectedProduct) ? productIndex : ''}
                                                            onChange={(event: any) => {
                                                                setPlanQty(event.target.value)
                                                                // setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                                // setProductIndex(event.target.value);
                                                            }}
                                                            disabled={selectedProxyType === 404}
                                                        >
                                                            <option value="" disabled>Select Quantity</option>
                                                            {getPlanKeys().map((key, index) => (
                                                                <option key={index} label={createLabel(key)} value={key}>{key}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div>
                                                    {/* <ListGroup className="list-group-flush purchase-list-group"> */}
                                                    {/* <ListGroupItem> */}
                                                    {validDiscountDC && (
                                                        <Form.Label>
                                                            {validDiscountDC && (
                                                                'Discount has been applied!'
                                                            )}
                                                        </Form.Label>
                                                    )}
                                                    <Form.Control
                                                        type="text"
                                                        value={discountValueDC}
                                                        onChange={(event: any) => {
                                                            setDiscountValueDC(event.target.value);
                                                        }}
                                                        onKeyPress={(event: any) => {
                                                            if (event.charCode === 13) {
                                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                    let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                    if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                        setDiscountBeingUsedDC(discountCode);
                                                                        return discountCode;
                                                                    }
                                                                });
                                                                if (isValidCode) {
                                                                    setValidDiscountDC(true);
                                                                } else {
                                                                    setValidDiscountDC(false);
                                                                }
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                    setDiscountBeingUsedDC(discountCode);
                                                                    return discountCode;
                                                                }
                                                            });
                                                            if (isValidCode) {
                                                                setValidDiscountDC(true);
                                                            } else {
                                                                setValidDiscountDC(false);
                                                            }
                                                        }}
                                                        placeholder="Enter discount code here"
                                                    />

                                                    {/* </ListGroupItem> */}
                                                    {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
                                                    {/* </ListGroup> */}
                                                </div>
                                                {/* </div> */}
                                                <div className="ffont-size t-text-lg t-space-y-3 grow">
                                                    {/* <p className="gray-border">30 Day Plan</p> */}
                                                    <p className="gray-border">30 Day Plan</p>
                                                    <p className="gray-border">User:Pass Auth</p>
                                                    <p className="gray-border">24/7 Excluding Shopify, Footsites, & YeezySupply</p>
                                                    {/* <p className="gray-border">Static & Rotating Proxies</p> */}
                                                    {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
                                                    <p className="gray-border">Footsites (not guaranteed), Yeezy Supply, Supreme, Shopify, & More</p>
                                                    <p className="gray-border">Hosted in Ashburn, VA</p>
                                                    {/* <p className="t-text-transparent">A</p> */}
                                                </div>
                                                <div>
                                                    <Button
                                                        className="t-w-full t-text-xl purchasecoke"
                                                        disabled={!isInStock}
                                                        onClick={handlePurchaseDC}

                                                    >
                                                        {!!isInStock ? "Purchase" : "SOLD OUT"}
                                                    </Button>
                                                </div>
                                                {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

                                            </div>
                                        </div>
                                    </div>
                                )}

                                {proxyType == 'dc' && (

                                    <div>
                                        <div className="text-2xl t-flex t-justify-center">
                                            <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
                                                {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

                                                {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
                                                <div className="t-bg-transparent">

                                                    <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                                                        <h1>Madhatter ISP Plan</h1>
                                                        <h2>24/7 Plan</h2>
                                                        <Form.Label className='text t-mx-1 t-mt-6'>Please select your proxy package</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            defaultValue=""
                                                            value={(selectedProduct) ? productIndex : ''}
                                                            onChange={(event: any) => {
                                                                setPlanQtyS(event.target.value)
                                                                // setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                                // setProductIndex(event.target.value);
                                                            }}
                                                            disabled={selectedProxyType === 404}
                                                        >
                                                            <option value="" disabled>Select Quantity</option>
                                                            {getPlanKeysS().map((key, index) => (
                                                                <option key={index} label={createLabelS(key)} value={key}>{key}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div>
                                                    {/* <ListGroup className="list-group-flush purchase-list-group"> */}
                                                    {/* <ListGroupItem> */}
                                                    {validDiscountDC && (
                                                        <Form.Label>
                                                            {validDiscountDC && (
                                                                'Discount has been applied!'
                                                            )}
                                                        </Form.Label>
                                                    )}
                                                    <Form.Control
                                                        type="text"
                                                        value={discountValueDC}
                                                        onChange={(event: any) => {
                                                            setDiscountValueDC(event.target.value);
                                                        }}
                                                        onKeyPress={(event: any) => {
                                                            if (event.charCode === 13) {
                                                                const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                    let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                    if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                        setDiscountBeingUsedDC(discountCode);
                                                                        return discountCode;
                                                                    }
                                                                });
                                                                if (isValidCode) {
                                                                    setValidDiscountDC(true);
                                                                } else {
                                                                    setValidDiscountDC(false);
                                                                }
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            const isValidCode = validDiscountCodes.find((discountCode) => {
                                                                let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                                                                if (isDc && discountCode.name.toLowerCase() === discountValueDC.toLowerCase()) {
                                                                    setDiscountBeingUsedDC(discountCode);
                                                                    return discountCode;
                                                                }
                                                            });
                                                            if (isValidCode) {
                                                                setValidDiscountDC(true);
                                                            } else {
                                                                setValidDiscountDC(false);
                                                            }
                                                        }}
                                                        placeholder="Enter discount code here"
                                                    />

                                                    {/* </ListGroupItem> */}
                                                    {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
                                                    {/* </ListGroup> */}
                                                </div>
                                                {/* </div> */}
                                                <div className="ffont-size t-text-lg t-space-y-3 grow">
                                                    {/* <p className="gray-border">30 Day Plan</p> */}
                                                    <p className="gray-border">30 Day Plan</p>
                                                    <p className="gray-border">User:Pass Auth</p>
                                                    <p className="gray-border">24/7 All sites excluding Footsites & YeezySupply</p>
                                                    {/* <p className="gray-border">Static & Rotating Proxies</p> */}
                                                    {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
                                                    <p className="gray-border">Footsites (not guaranteed), Yeezy Supply, Supreme, Shopify, & More</p>
                                                    <p className="gray-border">Hosted in Ashburn, VA</p>
                                                    {/* <p className="t-text-transparent">A</p> */}
                                                </div>
                                                <div>
                                                    <Button
                                                        className="t-w-full t-text-xl purchasecoke"
                                                        disabled={!isInStockS}
                                                        onClick={handlePurchaseS}

                                                    >
                                                        {!!isInStockS ? "Purchase" : "SOLD OUT"}
                                                    </Button>
                                                </div>
                                                {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="">
                                {showDC && (


                                    <Modal show={showDC} centered onHide={handleCloseDC} className="proxy-modal">
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {needsPassword ? (
                                                    <>
                                                        <p>PASSWORD</p>
                                                    </>
                                                ) : (
                                                    <div>
                                                        {hasAgreedToTerms ? (
                                                            <>
                                                                <p>PURCHASE NOW</p>
                                                                <p style={{ fontSize: '1rem' }}>{selectedProduct.description}</p>
                                                            </>
                                                        ) : (<p>TERMS OF SALE</p>)}
                                                    </div>
                                                )}

                                                {/* )} */}
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {needsPassword ? (<div>
                                                <div className="t-flex t-flex-col t-mx-3">
                                                    <Form.Label className='text t-mt-1 t-mb-3 t-text-lg'>Please enter your password.</Form.Label>
                                                    <Form.Control
                                                        id="dc-pw-input"
                                                        className="t-mb-3"
                                                        type="text"
                                                        onChange={(event: any) => {
                                                            console.log('pw changed', event.target.value)
                                                            handlePassword(event, null)

                                                            // setDiscountValueDC(event.target.value);
                                                        }}
                                                        onKeyPress={(event: any) => {
                                                            console.log('key press')
                                                            handlePassword(event, null)
                                                        }}
                                                        placeholder="Enter password here"
                                                    />
                                                </div>
                                            </div>) : (<div>
                                                {/* {hasAgreedToTerms ? <DCStripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsedDC.discount} discountValue={discountValueDC} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)} */}
                                                {hasAgreedToTerms ? <DCStripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated ? isAuthenticated : isLoggedIn} discountAmount={discountBeingUsedDC.discount} discountValue={validDiscountDC ? discountValueDC : ''} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                                            </div>)}
                                            {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {hasAgreedToTerms ? (
                                                <Button
                                                    variant="primary"
                                                    className="purchase-button"
                                                    onClick={() => {
                                                        handleCloseDC();
                                                        setHasAgreedToTerms(false);
                                                    }}
                                                >
                                                    CANCEL
                                                </Button>
                                            ) : (
                                                <Button variant="primary" className="purchase-button" onClick={() => {
                                                    if (needsPassword) {
                                                        let elementId = 'dc-pw-input'
                                                        let val = (document.getElementById(elementId) as HTMLInputElement).value;

                                                        {/* let val = document.getElementById('dc-pw-input')!.value */ }
                                                        let fakeEvent = {
                                                            charCode: 13,
                                                            target: {
                                                                value: val
                                                            }
                                                        }
                                                        console.log(fakeEvent)
                                                        handlePassword(fakeEvent, null)
                                                    } else {
                                                        setHasAgreedToTerms(true);
                                                    }
                                                }}>
                                                    {needsPassword ? 'ENTER' : 'I AGREE'}
                                                    {/* I AGREE */}
                                                </Button>
                                            )}
                                        </Modal.Footer>
                                    </Modal>
                                )}

                                {showShopify && (
                                    <div className="">

                                        <Modal show={showShopify} centered onHide={handleCloseS} className="proxy-modal">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {needsPassword ? (
                                                        <>
                                                            <p>PASSWORD</p>
                                                        </>
                                                    ) : (
                                                        <div>
                                                            {hasAgreedToTerms ? (
                                                                <>
                                                                    <p>PURCHASE NOW</p>
                                                                    <p style={{ fontSize: '1rem' }}>{selectedProduct.description}</p>
                                                                </>
                                                            ) : (<p>TERMS OF SALE</p>)}
                                                        </div>
                                                    )}

                                                    {/* )} */}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {needsPassword ? (<div>
                                                    <div className="t-flex t-flex-col t-mx-3">
                                                        <Form.Label className='text t-mt-1 t-mb-3 t-text-lg'>Please enter your password.</Form.Label>
                                                        <Form.Control
                                                            id="dc-pw-input"
                                                            className="t-mb-3"
                                                            type="text"
                                                            onChange={(event: any) => {
                                                                console.log('pw changed', event.target.value)
                                                                handlePassword(event, null)

                                                                // setDiscountValueDC(event.target.value);
                                                            }}
                                                            onKeyPress={(event: any) => {
                                                                console.log('key press')
                                                                handlePassword(event, null)
                                                            }}
                                                            placeholder="Enter password here"
                                                        />
                                                    </div>
                                                </div>) : (<div>
                                                    {/* {hasAgreedToTerms ? <DCStripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsedDC.discount} discountValue={discountValueDC} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)} */}
                                                    {hasAgreedToTerms ? <SStripe selectedProduct={getSelectedProductS()} userData={userData} isAuthenticated={isAuthenticated ? isAuthenticated : isLoggedIn} discountAmount={discountBeingUsedDC.discount} discountValue={validDiscountDC ? discountValueDC : ''} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                                                </div>)}
                                                {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {hasAgreedToTerms ? (
                                                    <Button
                                                        variant="primary"
                                                        className="purchase-button"
                                                        onClick={() => {
                                                            handleCloseS();
                                                            setHasAgreedToTerms(false);
                                                        }}
                                                    >
                                                        CANCEL
                                                    </Button>
                                                ) : (
                                                    <Button variant="primary" className="purchase-button" onClick={() => {
                                                        if (needsPassword) {
                                                            let elementId = 'dc-pw-input'
                                                            let val = (document.getElementById(elementId) as HTMLInputElement).value;

                                                            {/* let val = document.getElementById('dc-pw-input')!.value */ }
                                                            let fakeEvent = {
                                                                charCode: 13,
                                                                target: {
                                                                    value: val
                                                                }
                                                            }
                                                            console.log(fakeEvent)
                                                            handlePassword(fakeEvent, null)
                                                        } else {
                                                            setHasAgreedToTerms(true);
                                                        }
                                                    }}>
                                                        {needsPassword ? 'ENTER' : 'I AGREE'}
                                                        {/* I AGREE */}
                                                    </Button>
                                                )}
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                )}
                            </div>
                            {showResi && (

                                <Modal show={showResi} centered onHide={handleCloseResi} className="proxy-modal">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {needsPassword ? (
                                                <>
                                                    <p>PASSWORD</p>
                                                </>
                                            ) : (
                                                <div>
                                                    {hasAgreedToTerms ? (
                                                        <>
                                                            <p>PURCHASE NOW</p>
                                                            <p style={{ fontSize: '1rem' }}>{selectedProduct.description}</p>
                                                        </>
                                                    ) : (<p>TERMS OF SALE</p>)}
                                                </div>
                                            )}

                                            {/* )} */}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {needsPassword ? (<div>
                                            <div className="t-flex t-flex-col t-mx-3">
                                                <Form.Label className='text t-mt-1 t-mb-3 t-text-lg'>Please enter your password.</Form.Label>
                                                <Form.Control
                                                    id="resi-pw-input"
                                                    className="t-mb-3"
                                                    type="text"
                                                    onChange={(event: any) => {
                                                        console.log('pw changed', event.target.value)
                                                        handlePassword(event, null)

                                                        // setDiscountValueDC(event.target.value);
                                                    }}
                                                    onKeyPress={(event: any) => {
                                                        console.log('key press')
                                                        handlePassword(event, null)
                                                    }}
                                                    placeholder="Enter password here"
                                                />
                                            </div>
                                        </div>) : (<div>
                                            {/* {hasAgreedToTerms ? <DCStripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsedDC.discount} discountValue={discountValueDC} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)} */}
                                            {hasAgreedToTerms ? <Stripe selectedProduct={selectedProduct} userData={userData} isAuthenticated={isAuthenticated ? isAuthenticated : isLoggedIn} discountAmount={discountBeingUsedResi.discount} discountValue={validDiscountResi ? discountValueResi : ''} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                                        </div>)}
                                        {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {hasAgreedToTerms ? (
                                            <Button
                                                variant="primary"
                                                className="purchase-button"
                                                onClick={() => {
                                                    handleCloseResi();
                                                    setHasAgreedToTerms(false);
                                                }}
                                            >
                                                CANCEL
                                            </Button>
                                        ) : (
                                            <Button variant="primary" className="purchase-button" onClick={() => {
                                                if (needsPassword) {
                                                    let elementId = 'resi-pw-input'
                                                    let val = (document.getElementById(elementId) as HTMLInputElement).value;

                                                    {/* let val = document.getElementById('dc-pw-input')!.value */ }
                                                    let fakeEvent = {
                                                        charCode: 13,
                                                        target: {
                                                            value: val
                                                        }
                                                    }
                                                    console.log(fakeEvent)
                                                    handlePassword(fakeEvent, null)
                                                } else {
                                                    setHasAgreedToTerms(true);
                                                }
                                            }}>
                                                {needsPassword ? 'ENTER' : 'I AGREE'}
                                                {/* I AGREE */}
                                            </Button>
                                        )}
                                    </Modal.Footer>
                                </Modal>
                            )}

                            {/* <Nav.Link as={NavLink} exact to="/dashboard/proxies" className="black-hat-header-mini" style={{ marginBottom: '2rem', fontSize: '2rem' }}>Buy Residential?</Nav.Link> */}
                        </div>
                    )

                    }
                </div>
            </div>
        </div >
    )
}
export default Proxy;
