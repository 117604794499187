/* eslint-disable */
const devUrl = 'http://localhost:8000';
let prodUrl = 'https://api.blackhatproxies.dev';
//prodUrl = 'https://bh-server-vercel.bh.redapt.io'
//prodUrl="https://bh-server-stage.bh.redapt.io"

let baseURL = prodUrl;

if (process.env.NODE_ENV == 'production') {
    if (document.location.origin.includes('netlify')) {
        baseURL = 'https://bh-stage.redapt.io';
    } else {
        baseURL = prodUrl;
    }
}
else {
    // baseURL = devUrl;
}
let cfg = {
    baseURL,

}
export default cfg
