/* eslint-disable */
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Navbar, Nav, Button, Image,
} from 'react-bootstrap';
import DiscordService from '../../services/DiscordService';
import BlackHatLogo from '../../assets/blackhatlogo.svg';
import NewNav from '../NewNav/NewNav';

import './_navbar.scss';
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from 'typescript';

interface IProps {
  showDCPw?: string;
  isAuthenticated?: boolean;
  setIsAuthenticated: any;
  isLoggedIn?: any;
  setIsLoggedIn: any;
}
const NavbarWrapper: React.FC<IProps> = (props: any) => {
  const {
    isAuthenticated, setIsAuthenticated, isLoggedIn, setIsLoggedIn,
    showDCPw
  } = props;
  const history = useHistory();

  const getISPLink = () => {
    return `/dcpassword?x=${showDCPw}`
  }

  const getResiLink = () => {
    let u = `/password`
    if (showDCPw) u += `?x=${showDCPw}`
    return u
  }
  function goHome() {
    history.push('/')
  }

  return (
    <div>

      <Navbar className="md:t-hidden" expand="lg" variant="dark" style={{ fontWeight: 500 }}>
        <Navbar.Brand onClick={goHome}>
          {(isAuthenticated || isLoggedIn) && (
            <Image src={BlackHatLogo} className="App-logo" style={{ height: '5rem' }} alt="logo" />
          )}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {!isAuthenticated && (
            <Nav.Link
              as={NavLink}
              exact
              to={getResiLink()}
              onClick={async () => {
                if (!isLoggedIn) {
                  const onGetLoginURISuccess = (response: any) => {
                    if (response.data) {
                      const url = decodeURIComponent(response.data);
                      window.location.href = url;
                    }
                  };
                  const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
                  await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
                }
              }}
              className="navbar-link"
              activeStyle={{ borderBottom: '1px solid #fff' }}
            >
              {showDCPw ? 'Buy Resi' : 'Password'}
            </Nav.Link>
          )}
          {showDCPw && (
            <Nav.Link
              as={NavLink}
              exact
              to={getISPLink()}
              onClick={async () => {
                if (!isLoggedIn) {
                  const onGetLoginURISuccess = (response: any) => {
                    if (response.data) {
                      const url = decodeURIComponent(response.data);
                      window.location.href = url;
                    }
                  };
                  const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
                  await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
                }
              }}
              className="navbar-link"
              activeStyle={{ borderBottom: '1px solid #fff' }}
            >
              Buy ISP
            </Nav.Link>
          )}
          {isAuthenticated && (
            <>
              {/* <Nav.Link as={NavLink} exact to="/" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Home
          </Nav.Link> */}
              <Nav.Link as={NavLink} exact to="/dashboard/user" className="navbar-link gg" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Dashboard
          </Nav.Link>
              <Nav.Link as={NavLink} exact to="/shop" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Shop
          </Nav.Link>
              <Nav.Link as={NavLink} exact to="/dashboard/generate" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Generate Proxies
          </Nav.Link>
              <Nav.Link as={NavLink} exact to="/dashboard/dc" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Madhatter ISP
          </Nav.Link>
              <Nav.Link as={NavLink} exact to="/terms" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                Terms
          </Nav.Link>
            </>
          )}
          <Nav className="mr-auto" />
          <Nav>
            {(isAuthenticated || isLoggedIn) ? (
              <Button
                className="login-button"
                onClick={async () => {
                  const onUserLogOutSuccess = () => {
                    if (setIsLoggedIn) {
                      setIsLoggedIn(false);
                    }
                    if (setIsAuthenticated) {
                      setIsAuthenticated(false);
                    }
                    localStorage.removeItem('authToken')
                    history.push('/');
                  };
                  const onUserLogOutError = (error: any) => Error(`Logout error: ${error.response}`);
                  await DiscordService.UserLogout(onUserLogOutSuccess, onUserLogOutError);
                }}
              >
                LOG OUT

              </Button>
            ) : (
                <Button
                  className="login-button"
                  onClick={async () => {
                    const onGetLoginURISuccess = (response: any) => {
                      if (response.data) {
                        const url = decodeURIComponent(response.data);
                        window.location.href = url;
                      }
                    };
                    const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
                    await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
                  }}
                >
                  DASHBOARD

                </Button>
              )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="md:t-block t-hidden t-mb-12">
        <NewNav showDCPw={showDCPw} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
      </div>
    </div>
  );

};

export default NavbarWrapper;
