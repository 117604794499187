/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import StripeService from '../../services/StripeService';
import DiscordService from '../../services/DiscordService';
import DCService from '../../services/ShService';

import getStripeKey from '../../helpers/stripeKey';


interface IProps {
  selectedProduct: any;
  userData: any;
  isAuthenticated: any;
  discountAmount: any;
  discountValue: any;
}
const Stripe: React.FC<IProps> = (props: any) => {
  const {
    selectedProduct, userData, isAuthenticated, discountAmount, discountValue,
  } = props;


  const stripeKey: any = getStripeKey()



  const handleSuccessfulLogIn = async () => {
    const onLoginSuccess = (response: any) => {
      if (response.data) {
        const url = decodeURIComponent(response.data);
        window.location.href = url;
      }
    };
    const onLoginError = (error: any) => Error(`Login error: ${error.response}`);

    DiscordService.GetLoginURI(onLoginSuccess, onLoginError);
  };

  const handlePurchase = async (sessionID: string) => {
    const stripe: any = await loadStripe(stripeKey);
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionID,
    });
  };

  const handleInitiateStripeCheckoutSession = (product) => {
    const handleCheckoutSuccess = (response: any) => {
      const sessionID = response.data.id;
      handlePurchase(sessionID);
    };

    const handleCheckoutFailure = (error: any) => {
      console.log('checkout failure: ', error.response);
      return new Error(error);
    };

    const newAmount = discountValue ? ((product.amount * (1 - discountAmount / 100))) * 100 : product.amount * 100;
    const fixedAmount = (newAmount < 1) ? 50 : newAmount.toFixed();
    const postData = {
      count: product.count,
      amount: product.amount,
      discordData: {
        id: userData.user_id || userData.id,
        username: userData.username,
      },
      // sku: {
      //   sku: product.sku.sku,
      // },
      proxyType: product.type,
      discountValue,
      // productID: product._id,
    };

    console.log('POST DATA: ', postData);
    DCService.createDCCheckout(
      postData,
      handleCheckoutSuccess,
      handleCheckoutFailure,
    );
  };
  const handleCheckout = () => {
    if (!isAuthenticated) {
      handleSuccessfulLogIn();
    } else {
      handleInitiateStripeCheckoutSession(selectedProduct);
    }
  };
  return (
    <Row>
      <Col>
        <h3>
          Proxies bought:
          {selectedProduct.count}
        </h3>
        <h3>
          Price: $
          {discountValue ? ((selectedProduct.amount * (1 - discountAmount / 100))).toFixed(2) : selectedProduct.amount}
        </h3>
        <Row>
          <Col>
            <Button
              style={{ float: 'right' }}
              variant="primary"
              className="purchase-button"
              onClick={handleCheckout}
            >
              CHECK OUT
            </Button>
          </Col>
        </Row>

      </Col>

    </Row>
  );
};

export default Stripe;
