/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button, Container, Row, Col, Jumbotron, Navbar, Nav,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import NavbarWrapper from '../Navbar/NavbarWrapper';
import '../../App.scss';
import Terms from './Terms'

interface IProps {
  isAuthenticated: boolean;
  setIsAuthenticated: any;
  userData: any;
  setIsLoggedIn: any;
}

const TermsWrapper: React.FC<IProps> = (props: any) => {
  const {
    isAuthenticated, setIsAuthenticated, userData, setIsLoggedIn,
  } = props;
  const { path } = useRouteMatch();


  return (
    <>
      <Container className="App splash-background" fluid style={{ display: 'flex', flexDirection: 'column' }}>
        <Container>
          <NavbarWrapper isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsLoggedIn={setIsLoggedIn} />
        </Container>
        <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Row style={{ height: '100%', justifyContent: 'center' }}>
            <Terms userData={userData} isAuthenticated={isAuthenticated}></Terms>

            {/*<Switch>
              <Route exact path={`${path}/proxies`}>

                <Proxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>
              <Route exact path={`${path}/dcproxies`}>
                <DCProxy userData={userData} isAuthenticated={isAuthenticated} />
              </Route>
              <Route exact path={`${path}/dc`}>
              <Datacenter userData={userData}/>
              </Route>
              <Route exact path={`${path}/user`}>
                <User userData={userData} />
              </Route>
              <Route exact path={`${path}/generate`}>
                <Generate userData={userData} />
              </Route>
            </Switch> */}
          </Row>

        </Container>

      </Container>
      {/* <Footer /> */}

    </>
  );
};

export default TermsWrapper;
