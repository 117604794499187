/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import {
  Nav, Row, Col, Jumbotron, Button, Card, ListGroup, ListGroupItem, Form, Modal, ButtonGroup,
} from 'react-bootstrap';
import axios from 'axios';
import ReactTypingEffect from 'react-typing-effect';
import Stripe from '../../Stripe/InvoiceStripe';
import SSStripe from '../../Stripe/InvoiceStripeShopify';
import ProductService from '../../../services/ProductService';
import DiscordService from '../../../services/DiscordService';
import DiscountService from '../../../services/DiscountService';
import ProxyCheapService from '../../../services/ProxyCheapService';
import DCService from '../../../services/DCService';
import ShService from '../../../services/ShService';

interface IProps {
  userData: any;
  isAuthenticated: any;
}

const Proxy: React.FC<IProps> = (props: any) => {
  const { userData, isAuthenticated } = props;
  // const [selectedProduct, setSelectedProduct] = useState() as any;
  // const [productIndex, setProductIndex] = useState();
  const [planQty, setPlanQty] = useState(25)
  const [timespan, setTimespan] = useState(null)
  const [invoiceData, setInvoiceData] = useState({} as any);
  const [qtyPlans, setPlans] = useState({})
  const [isInStock, setisInstock] = useState(true)
  const [stockData, setStockData] = useState({})
  const [isShopify, setIsShopify] = useState(false)
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [show, setShow] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [discountValue, setDiscountValue] = useState('');
  const [validDiscount, setValidDiscount] = useState(false);
  const [discountBeingUsed, setDiscountBeingUsed] = useState({} as any);

  let baseDescription = 'Black Hat Datacenter Proxies - Non Refundable. Digital Goods Delivered Upon Payment'

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPlanText = () => {
    var days = invoiceData.days
    if (!days) days = 30
    return `${days} Day`
  }
  function getDiscountedPrice(price, percent) {
    if (!percent) return price
    var p = (price * (1 - percent / 100)).toFixed(2)
    return p
  }
  function createLabel(key) {
    var planPrice = qtyPlans[key]
    var price = validDiscount ? getDiscountedPrice(planPrice, discountBeingUsed.discount) : planPrice
    return `${key} - $${price}`
  }
  function makeInvoiceLabel() {
    let { price, count } = invoiceData
    return `${count} - $${price}`

  }
  function getPlanKeys() {
    return Object.keys(qtyPlans)
  }

  function getSelectedProduct() {
    let planPrice = invoiceData.price
    let qty = invoiceData.count
    let p = {
      count: qty,
      amount: planPrice,
      type: 'dc',
      // amount:getDiscountedPrice(planPrice,discountBeingUsed.discount),
      name: 'Datacenter Proxies',
    }
    console.log(p)
    return p
  }


  var mockResp = {
    id: 'smth',
    userId: 'xx',
    qty: 500,
    price: 800
  }


  var getInvoice = async (id) => {
    console.log(`getting invoice data for ${id}`)
    let invoiceData = await DCService.getInvoice(id)
    if (!invoiceData || !invoiceData.price) {
      invoiceData = await ShService.getInvoice(id)
      setIsShopify(true)
    }
    console.log(`got invoice data:`, invoiceData)
    setInvoiceData(invoiceData)
    return mockResp

  }

  const startup = async () => {
    var urlParams = new URLSearchParams(window.location.search);
    let invoiceId = urlParams.get('id')
    console.log(`invoice startup fn with id ${invoiceId}`)
    let invoice = await getInvoice(invoiceId)
    let stockData = await DCService.getPlans();
    console.log(`got stock data:`, stockData)
    setStockData(stockData);
    let { inStock, reserved, total, plans } = stockData
    setisInstock(inStock)
    setPlans(plans)
    setIsFetchingProducts(false);
  }


  useEffect(() => {
    startup();
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);



  }, []);



  const handlePurchase = async () => {
    if (isAuthenticated) {
      handleShow()
    } else {
      const onGetLoginURISuccess = (response: any) => {
        if (response.data) {
          const url = decodeURIComponent(response.data);
          window.location.href = url;
        }
      };
      const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
      await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
    }
  };

  useEffect(() => {

  }, []);


  if (isProcessingPayment) {
    return (
      <div style={{ top: '50%', position: 'relative' }}>
        <ReactTypingEffect
          text="Processing . . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>

    );
  }


  return (
    <Row>
      <Col>
        {/* <h1 className="black-hat-header bg-red-500" style={{ marginTop: '5rem' }}>Datacenter Invoice</h1> */}

        <div className="text-2xl t-flex t-justify-center">
          <div className="gey t-flex t-flex-col t-items-scenter t-text-2xl t-w-full t-font-bold t-font-bold t-space-y-8 t-px-0">
            {/* <div className="t-mt-3 t-text-2xl">
                                            MagicHatter Resi Plan
      </div> */}

            {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label className="text t-text-xl">Please select your proxy package.</Form.Label>
                                            <Form.Control
                                                className="form-control t-text-lg t-mt-1"
                                                as="select"
                                                value={planQty || ''}
                                                onChange={(event: any) => {
                                                    console.log(`form control changed`)
                                                    console.log(event.target.value)
                                                    setPlanQty(event.target.value)
                                                }}
                                            >
                                                <option value="">Select Quantity</option>

                                            </Form.Control>
                                        </Form.Group> */}
            <div className="t-bg-transparent">

              <Form.Group className="t-bg-transparent" controlId="exampleForm.ControlSelect1">
                <h1>ISP Invoice</h1>
                <h4 className="t-mt-1" style={{ fontWeight: 500 }}>All sales are final.</h4>
                <h2 className="t-mt-4">Plan: {getPlanText()}</h2>
                <Form.Label className="text t-mt-6">Please select your proxy package</Form.Label>
                <Form.Control
                  as="select"
                  value={planQty || ''}
                  onChange={(event: any) => {
                    console.log(`form control changed`)
                    console.log(event.target.value)
                    setPlanQty(event.target.value)
                  }}
                >
                  <option value="" disabled>Select Quantity</option>
                  <option label={makeInvoiceLabel()}>{invoiceData.count}</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div>
              {/* <ListGroup className="list-group-flush purchase-list-group"> */}
              {/* <ListGroupItem> */}

              {/* </ListGroupItem> */}
              {/* <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                                <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                                <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                                <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem> */}
              {/* </ListGroup> */}
            </div>
            {/* </div> */}
            <div className="ffont-size t-text-lg t-space-y-3">
              <p className="gray-border">No Expiration</p>
              <p className="gray-border">User:Pass Auth</p>
              <p className="gray-border">Static & Rotating Proxies</p>
              {/* <p className="gray-border">STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</p> */}
              <p className="gray-border">Footsites, Yeezy Supply, Supreme, Shopify, & More</p>
            </div>
            <div>
              <Button
                className="purchase-button t-w-full t-font-bold t-text-xl t-mt-6"
                onClick={handlePurchase}
              >
                Purchase
                    </Button>
            </div>
            {/* <select className="form-control t-w-full">
                                            <option>One</option>
                                        </select> */}

          </div>
        </div>
        {/*<ButtonGroup size="lg" aria-label="Basic example">
          <Button
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`some button clcked`);
              // setSelectedProxyType(proxiwareResi);
            }}
          >
            60-Day Plan

          </Button>
          <Button
            disabled={false}
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`other button clicked non exp`)
            }}
          >
            Non-Expiry Plan

          </Button>
        </ButtonGroup> */}
        {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
        <Modal show={show} centered onHide={handleClose} className="proxy-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              {hasAgreedToTerms ? (
                <>
                  <p>PURCHASE NOW</p>
                  <p style={{ fontSize: '1rem' }}>{baseDescription}</p>
                </>
              ) : (<p>TERMS OF SALE</p>)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
            {isShopify ? (
              <div>
                { hasAgreedToTerms ? <SSStripe invoiceData={invoiceData} selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsed.discount} discountValue={discountValue} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
              </div>
            )
              : (
                <div>

                  { hasAgreedToTerms ? <Stripe invoiceData={invoiceData} selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsed.discount} discountValue={discountValue} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            {hasAgreedToTerms ? (
              <Button
                variant="primary"
                className="purchase-button"
                onClick={() => {
                  handleClose();
                  setHasAgreedToTerms(false);
                }}
              >
                CANCEL
              </Button>
            ) : (
                <Button variant="primary" className="purchase-button" onClick={() => { setHasAgreedToTerms(true); }}>
                  I AGREE
                </Button>
              )}
          </Modal.Footer>
        </Modal>

        {/*<Nav.Link as={NavLink} exact to="/dashboard/proxies" className="black-hat-header-mini" style={{ marginBottom: '2rem', fontSize: '2rem' }}>Buy Residential?</Nav.Link> */}
      </Col>
    </Row>
  );
}

export default Proxy;
