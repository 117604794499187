/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Row, Col, Jumbotron, Image, Button, Form,
} from 'react-bootstrap';
import './_user.scss';
import ReactTypingEffect from 'react-typing-effect';
import DataGrid, {
  Column, Scrolling, Pager, Paging, Editing,
} from 'devextreme-react/data-grid';
import DiscordService from '../../../services/DiscordService';
import ProxyService from '../../../services/ProxyService';
import RevenueService from '../../../services/RevenueService';
import ProductService from '../../../services/ProductService';
import UserService from '../../../services/UserService';
import DiscountService from '../../../services/DiscountService';
import PasswordService from '../../../services/PasswordService';
import NetnutService from '../../../services/NetnutService';
import cacheData from '../../../helpers/cacheData';
import SubService from '../../../services/SubWorks';
import DCService from '../../../services/DCService';

interface IProps {
  userData: any;
}


const User: React.FC<IProps> = (props: any) => {
  // let randomRef = React.createRef()
  const randomRef = React.useRef<HTMLDivElement>(null) as any;
  const { userData } = props;
  const [greeting, setGreeting] = useState('');
  const [userDashboardData, setUserDashboardData] = useState({}) as any;
  const [netnutUserDataRemaining, setNetnutUserDataRemaining] = useState('N/A');
  const [proxyCheapUserDataRemaining, setProxyCheapUserDataRemaining] = useState('N/A');
  const [packetStreamUserDataRemaining, setPacketStreamUserDataRemaining] = useState('N/A');
  const [isFetchingDashboardData, setIsFetchingDashboardData] = useState(true);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [inventory, setInventory] = useState({}) as any;
  const [userList, setUserList] = useState([]);
  const [totalActiveData, setTotalActiveData] = useState(0) as any;
  const [isRevenueListToggled, setIsRevenueListToggled] = useState(true);
  const [isDiscountViewToggled, setIsDiscountViewToggled] = useState(false);
  const [isPasswordViewToggled, setIsPasswordViewToggled] = useState(false);
  const [revenue, setRevenue] = useState([]) as any;
  const [hasBeenBound, setHasBeenBound] = useState(null) as any;
  const [expirationDate, setExpirationDate] = useState() as any;
  const [selectedDiscountCodes, setSelectedDiscountCodes] = useState([] as any);
  const [remainingData, setRemainingData] = useState('N/A');
  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [validPasswords, setValidPasswords] = useState([] as any);
  const [subWorksDataRemaining, setSubRemaining] = useState(0 as any);
  const [userproxyGroups, setuserProxyGroups] = useState([] as any);
  const [subWorksUserDataRemaining, setSubUserRemaining] = useState(0 as any);


  const [discountCode, setDiscountCode] = useState({
    worksOn: 'both',
    name: '',
    discount: '',
    expirationDate: '',
    numberOfUses: '',
  });


  const [password, setPassword] = useState({
    name: '',
    expirationDate: '',
    numberOfUses: '',
  });

  const getProxyGroups = async function () {
    console.log('getting proxy groups..');
    const resp = await DCService.getUserGroups();
    console.debug('mygroups:', resp);
    setuserProxyGroups(resp);
    // if (!activeGroupId && resp.length > 0) setActiveGroupId(resp[0].id);
    return resp;
  };
  const getUserDetails = async () => {
    const onGetUserRoleSuccess = (response: any) => {
      /*
      if(response.data.orders) {
        let os = response.data.orders.map(function (o){
          console.log(os)
          if(!o.proxyAmount && o.dcData.count )o.proxyAmount = o.dcData.count
          return o
          response.data.orders = os
        })
      }
      console.log(69999,response.data)
      */
      setUserDashboardData(response.data);
      const newDataString = response.data.netnutData;
      if (response.data.subworksUserData) {
        let { subworksUserData } = response.data
        let { dataUsage, dataLimitMegaBytes } = subworksUserData
        console.log(dataUsage, dataLimitMegaBytes, 5555)
        let xx = dataLimitMegaBytes - (dataUsage / 1000000)
        // console.log(mbRemaining)
        let gb = xx / 1000
        let resultNum = parseFloat(gb.toFixed(2));
        // setSubRemaining(resultNum + 'GB')
        setSubUserRemaining(resultNum + 'GB')
        // setSelectedProxyType(proxyWorksResi);

      }
      if (response.data.proxyCheap) {
        const proxyCheapBalance = response.data.proxyCheap.balance;
        setProxyCheapUserDataRemaining(`${proxyCheapBalance / 100}GB`);
      }
      if (response.data.packetStream) {
        const packetStreamBalance = response.data.packetStream.balance;
        setPacketStreamUserDataRemaining(`${packetStreamBalance / 100}GB`);
      }
      if (newDataString) {
        setNetnutUserDataRemaining(`${newDataString}GB`);
      }
      const date = new Date(response.data.data_expiry);
      const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (newDate) {
        setExpirationDate(newDate);
      }
      setIsFetchingDashboardData(false);
    };

    if (JSON.stringify(cacheData.userRole) != "{}") {
      onGetUserRoleSuccess(cacheData.userRole);
    }
    const onGetUserRoleError = (error: any) => {
      console.log('ERROR: ', error.response);
    };
    await DiscordService.GetUserRole(userData.user_id, onGetUserRoleSuccess, onGetUserRoleError);
  };

  const getRevenue = async () => {
    const onGetRevenueSuccess = (response: any) => {
      let total = 0;
      response.data.forEach(({ profit }: any) => {
        total += parseFloat(profit);
      });
      setRevenue(response.data);
      setTotalRevenue(total);
    };
    const onGetRevenueError = (error: any) => {
      console.log('ERROR: ', error.response);
    };
    await RevenueService.GetAll(onGetRevenueSuccess, onGetRevenueError);
  };

  const getInventory = async () => {
    const onGetInventorySuccess = (response: any) => {
      setInventory(response.data);
    };
    const onGetInventoryError = (error: any) => {
      console.log('ERROR: ', error.response);
    };
    await NetnutService.GetRemainingStock(onGetInventorySuccess, onGetInventoryError);
  };

  const toggleList = () => {
    setIsRevenueListToggled(!isRevenueListToggled);
    setIsDiscountViewToggled(false);
    setIsDiscountViewToggled(false);
    setIsPasswordViewToggled(false);
  };

  const toggleDiscount = () => {
    setIsDiscountViewToggled(true);
    setIsPasswordViewToggled(false);
    setIsRevenueListToggled(false);
  };

  const togglePassword = () => {
    setIsPasswordViewToggled(true);
    setIsRevenueListToggled(false);
    setIsDiscountViewToggled(false);
  };

  const getAllUsers = async () => {
    const onGetUsersSuccess = (response: any) => {
      let totalAvailableData = 0;
      setUserList(response.data);
      response.data.forEach((user) => {
        let newDataString = user.netnutData.replace(/(:.{0,3}KB)|(:.{0,3}B)/gi, '').replace(':', '.');
        if (!newDataString) {
          newDataString = 0;
        }

        if (newDataString.match(/(\..{0,3}MB)/gi)) {
          newDataString = newDataString.replace(/mb|gb/gi, '');
        } else if (newDataString.match(/mb/gi)) {
          newDataString = newDataString.replace(/mb/gi, '');
          newDataString = `.${newDataString}`;
        } else if (newDataString.match(/gb/gi)) {
          newDataString = newDataString.replace(/gb/gi, '');
        }

        totalAvailableData += parseFloat(newDataString);
        setTotalActiveData(totalAvailableData);
      });
    };
    const onGetUsersError = (error: any) => {
      console.log('ERROR: ', error.response, error);
    };
    await UserService.GetAll(onGetUsersSuccess, onGetUsersError);
  };


  const setTimeGreeting = () => {
    const time = new Date().getHours();
    if (time < 10) {
      setGreeting('Good Morning');
    } else if (time < 20) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  };

  const getRemainingData = () => {
    const onGetDataSuccess = (response: any) => {
      setRemainingData(`${response.data.balance / 100}GB`);
    };
    const onGetDataError = (error: any) => {
      console.log('ERROR: ', error.response);
    };
    ProxyService.GetRemainingData(onGetDataSuccess, onGetDataError);
  };
  const getTotalProxyCount = function () {
    let count = 0;
    let proxyGroups = userproxyGroups;
    for (let i = 0; i < proxyGroups.length; i++) {
      const g = proxyGroups[i];
      count += g.count || 0;
    }
    return count;
  };
  const getRemainingSub = async () => {
    try {
      let result = await SubService.getRemaining()
      if (result && result.dataLimitGB) {
        var x = result.dataLimitGB - result.dataSoldGB
        x = parseFloat(x.toFixed(2))
        setSubRemaining(x)
      }
    } catch (e) {

    }

    // setSubRemaining(result.)
  }

  useEffect(() => {
    getUserDetails();
    getRevenue();
    getInventory();
    getAllUsers();
    setTimeGreeting();
    getRemainingData();
    getRemainingSub()
    getProxyGroups()
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);

    const onGetValidPasswordSuccess = (response: any) => {
      setValidPasswords([...response.data]);
    };
    const onGetValidPasswordError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    PasswordService.GetValid(onGetValidPasswordSuccess, onGetValidPasswordError);
  }, []);


  if (isFetchingDashboardData) {
    return (
      <div
        style={{
          textAlign: 'center', position: 'relative', top: '50vh',
        }}
      >
        <ReactTypingEffect
          text=". . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>
    );
  }


  const handleDiscountChange = (event: any) => {
    const { name, value } = event.target;
    console.log(name, value)
    setDiscountCode({ ...discountCode, [name]: value });
  };

  const handlePasswordChange = (event: any) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  const handleDiscountSubmit = (event: any) => {
    event.preventDefault();
    const onGenerateDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
      setDiscountCode({
        worksOn: 'both',
        name: '',
        discount: '',
        numberOfUses: '',
        expirationDate: '',
      });
    };
    const onGenerateDiscountError = (error: any) => {
      const onGetValidDiscountSuccess = (response: any) => {
        setValidDiscountCodes([...response.data]);
      };
      const onGetValidDiscountError = (error: any) => {
        console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
      };
      DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);
      console.log('ERROR GENERATING DISCOUNT: ', error.response);
    };

    const hasNoExpiration = !discountCode.expirationDate;
    const hasNoLimit = !discountCode.numberOfUses;

    const discountRegex = /[^\d]/g;
    const date = new Date();
    const data = {
      name: discountCode.name,
      discount: parseInt(discountCode.discount.replace(discountRegex, ''), 10),
      expirationDate: date.setDate(date.getDate() + parseInt(discountCode.expirationDate.replace(discountRegex, ''), 10)),
      numberOfUses: parseInt(discountCode.numberOfUses.replace(discountRegex, ''), 10),
      worksOn: discountCode.worksOn,
      hasNoExpiration,
      hasNoLimit,
    };
    DiscountService.Generate(data, onGenerateDiscountSuccess, onGenerateDiscountError);
  };

  const handlePasswordSubmit = (event: any) => {
    event.preventDefault();
    const onGenerateDiscountSuccess = (response: any) => {
      setValidPasswords([...response.data]);
      setPassword({
        name: '',
        numberOfUses: '',
        expirationDate: '',
      });
    };
    const onGeneratePasswordError = (error: any) => {
      const onGetValidPasswordSuccess = (response: any) => {
        setValidPasswords([...response.data]);
      };
      const onGetValidPasswordError = (error: any) => {
        console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
      };
      PasswordService.GetValid(onGetValidPasswordSuccess, onGetValidPasswordError);
      console.log('ERROR GENERATING DISCOUNT: ', error.response);
    };

    const hasNoExpiration = !password.expirationDate;
    const hasNoLimit = !password.numberOfUses;

    const discountRegex = /[^\d]/g;
    const date = new Date();
    const data = {
      name: password.name,
      expirationDate: date.setDate(date.getDate() + parseInt(password.expirationDate.replace(discountRegex, ''), 10)),
      numberOfUses: parseInt(password.numberOfUses.replace(discountRegex, ''), 10),
      hasNoExpiration,
      hasNoLimit,
    };
    PasswordService.Generate(data, onGenerateDiscountSuccess, onGeneratePasswordError);
  };

  const handleVerifyIP = async () => {
    const onVerifyIPSuccess = () => {
      setHasBeenBound(true);
    };
    const onVerifyIPError = (error: any) => {
      setHasBeenBound(false);
      console.error('ERROR: ', error);
    };
    ProxyService.VerifyIP(userDashboardData.proxy_user_id, onVerifyIPSuccess, onVerifyIPError);
  };


  const pageSizes = [10, 25, 50, 100];

  const renderGridCell = (data) => {
    const date = new Date(data.data.createdAt);
    const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return newDate;
  };
  const renderAmount = (data) => {
    // console.log(data.data.dcData,69999)
    let x = data.data.proxyAmount
    if (!x && data.data.dcData) x = data.data.dcData.count
    return x
  }

  const renderType = (data) => {
    // return 'ff'
    // console.log(data.data.dcData,69999)
    let { proxyType } = data.data
    if (proxyType == 'dc') return 'datacenter'
    return proxyType
  }

  const renderExpiryGridCell = (data) => {
    const date = new Date(data.data.data_expiry);
    const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return newDate;
  };

  const renderNetnutData = (data) => {
    if (data.value) {
      return data.value.toFixed(2);
    }
    return 'N/A';
  };


  const renderProxyCheapData = (data) => {
    if (data.value) {
      return (data.value.balance / 100).toFixed(2);
    }
    return 'N/A';
  };


  return (
    <Row style={{ width: '100%' }}>
      <Col>
        <Jumbotron className="user-jumbotron">
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Col xs="auto">
              <h1 className="user-greeting">
                {greeting}
                ,
                {userDashboardData.username}
              </h1>
            </Col>
            <Col xs="auto">
              <Image
                className="discord-avatar"
                roundedCircle
                src={
                  userDashboardData.avatar
                    ? `https://cdn.discordapp.com/avatars/${userDashboardData.user_id}/${userDashboardData.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${userDashboardData.discriminator % 5}.png`
                }
              />
            </Col>
          </Row>
          <h5>
            {`Role: ${userDashboardData.role}`}
          </h5>
          {(userData.role === 'admin') ? (
            <>
              {(isRevenueListToggled ? (
                <>
                  {/* <h6 className={inventory > 0 ? '' : 'error-text'}>
                    Total Netnut Residential Inventory Data Remaining:
                    {`${inventory}GB`}
                  </h6> */}
                  {/* <h6 className={parseFloat(remainingData) > 0 ? '' : 'error-text'}>
                    Total Packet Stream Residential Inventory Data Remaining:
                    {remainingData}
                  </h6> */}
                  <h6 className={parseFloat(remainingData) > 0 ? '' : ''}>
                    Total SubnetWorks Data Remaining: {subWorksDataRemaining + 'GB'}
                  </h6>
                  <h6>
                    Total Revenue Earned: ${totalRevenue.toFixed(2)}
                  </h6>

                </>
              ) : (
                  <>
                    <h6>
                      {`Total Users: ${userList.length}`}
                    </h6>
                    <h6>
                      {`Total Active Data: ${totalActiveData.toFixed(2)}GB`}
                    </h6>
                  </>
                ))}
              <Row>
                <Col xs="auto">
                  <Button
                    className="bind-button"
                    onClick={toggleList}
                  >
                    {isRevenueListToggled ? 'Toggle to User List' : 'Toggle to Revenue List'}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    className="bind-button"
                    onClick={toggleDiscount}
                  >
                    Manage Discounts
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    className="bind-button"
                    onClick={togglePassword}
                  >
                    Manage Passwords
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
              <>

                <h6>
                  MagicHatter Data Left: {subWorksUserDataRemaining || 'N/A'}
                </h6>
                {userproxyGroups.length > 0 && (
                  <h6>
                    ISP Proxies: {getTotalProxyCount()}
                  </h6>
                )}
                {/* {(netnutUserDataRemaining !== 'N/A') && (
                  <h6>
                    Total Available 60-Day Data Left:
                    {netnutUserDataRemaining || 'N/A'}
                  </h6>
                )} */}
                {/* {(proxyCheapUserDataRemaining !== 'N/A') && (
                  <h6>
                    Total Available Non-Expiry Data Left (Old):
                    {proxyCheapUserDataRemaining}
                  </h6>
                )} */}
                {/* {(packetStreamUserDataRemaining !== 'N/A') && (
                  <h6>
                    Total Available Non-Expiry Data Left:
                    {packetStreamUserDataRemaining}
                  </h6>
                )} */}
                {/* {(expirationDate && !expirationDate.includes('NaN')) && (

                  <h6>
                    Expiration Date:
                    {expirationDate}
                  </h6>
                )} */}

              </>
            )}


        </Jumbotron>

        <Row noGutters className="datagrid-container">
          <Col>
            {(userData.role === 'admin') ? (
              <>
                {isPasswordViewToggled && (
                  <>
                    <Row style={{ textAlign: 'left', padding: '2rem' }}>
                      <Col xs={6}>
                        <Row>
                          <Col xs={6}>
                            <Form.Label>Password Code</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Password"
                              name="name"
                              onChange={handlePasswordChange}
                            />
                          </Col>
                          <Col xs={6}>
                            <Form.Label>Password Duration</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter duration in days"
                              name="expirationDate"
                              onChange={handlePasswordChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <Form.Label>Number of Uses</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter number of uses"
                              name="numberOfUses"
                              onChange={handlePasswordChange}
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '2rem' }}>
                          <Col xs={3}>
                            <Button
                              variant="primary"
                              className="generate-button"
                              type="submit"
                              onClick={handlePasswordSubmit}
                            >
                              CREATE
                            </Button>
                          </Col>

                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Form.Label>Generated Passwords</Form.Label>
                        <DataGrid
                          dataSource={validPasswords}
                          showBorders={false}
                          height={450}
                          onRowUpdated={(event: any) => {
                            const onUpdateSuccess = (response: any) => {
                            };
                            const onUpdateError = (error: any) => {
                              console.log('ERROR: ', error.response);
                            };
                            PasswordService.UpdateRow(event.data, onUpdateSuccess, onUpdateError);
                          }}
                          onRowRemoved={(event: any) => {
                            const onRowRemoveSuccess = (response: any) => {
                              // setValidDiscountCodes([...response.data]);
                            };
                            const onRowRemoveError = (error: any) => {
                              console.log('ERROR UPDATING: ', error.response);
                            };
                            PasswordService.RemoveRow(event.data._id, onRowRemoveSuccess, onRowRemoveError);
                          }}
                        >
                          <Editing
                            mode="cell"
                            allowUpdating
                            allowDeleting
                          />
                          <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                          <Paging defaultPageSize={10} />
                          <Scrolling mode="standard" />
                          <Column
                            dataField="name"
                            caption="Password Name"
                          />
                          <Column
                            dataField="expirationDate"
                            caption="Expiration Date"
                          />
                          <Column
                            dataField="numberOfUses"
                            caption="Number of Uses"
                          />

                        </DataGrid>
                      </Col>
                    </Row>
                  </>
                )}
                {isDiscountViewToggled && (
                  <Row style={{ textAlign: 'left', padding: '2rem' }}>
                    <Col xs={6}>
                      <Row>
                        <Col xs={6}>
                          <Form.Label>Discount Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter discount code name"
                            name="name"
                            onChange={handleDiscountChange}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Label>Discount Amount</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter discount amount"
                            name="discount"
                            onChange={handleDiscountChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Label>Discount Duration</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter duration in days"
                            name="expirationDate"
                            onChange={handleDiscountChange}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Label>Number of Uses</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter number of uses"
                            name="numberOfUses"
                            onChange={handleDiscountChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Label>Works On</Form.Label>

                          <Form.Control
                            as="select"
                            name="worksOn"
                            defaultValue="both"
                            onChange={handleDiscountChange}
                          >

                            <option value="both">both</option>
                            <option value="resi">resi</option>
                            <option value="dc">dc</option>
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col xs={3}>
                          <Button
                            variant="primary"
                            className="generate-button"
                            type="submit"
                            onClick={handleDiscountSubmit}
                          >
                            CREATE
                          </Button>
                        </Col>

                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Form.Label>Generated Discount Codes</Form.Label>
                      <DataGrid
                        dataSource={validDiscountCodes}
                        showBorders={false}
                        height={450}
                        onRowUpdated={(event: any) => {
                          const onUpdateSuccess = (response: any) => {
                          };
                          const onUpdateError = (error: any) => {
                            console.log('ERROR: ', error.response);
                          };
                          DiscountService.UpdateRow(event.data, onUpdateSuccess, onUpdateError);
                        }}
                        onRowRemoved={(event: any) => {
                          const onRowRemoveSuccess = (response: any) => {
                            // setValidDiscountCodes([...response.data]);
                          };
                          const onRowRemoveError = (error: any) => {
                            console.log('ERROR UPDATING: ', error.response);
                          };
                          DiscountService.RemoveRow(event.data._id, onRowRemoveSuccess, onRowRemoveError);
                        }}
                      >
                        <Editing
                          mode="cell"
                          allowUpdating
                          allowDeleting

                        />
                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                        <Paging defaultPageSize={10} />
                        <Scrolling mode="standard" />
                        <Column
                          dataField="name"
                          caption="Discount Name"
                        />
                        <Column
                          dataField="discount"
                          caption="Discount Amount"
                        />
                        <Column
                          dataField="expirationDate"
                          caption="Expiration Date"
                        />
                        <Column
                          dataField="numberOfUses"
                          caption="Number of Uses"
                        />

                      </DataGrid>
                    </Col>
                  </Row>
                )}
                <>
                  {(!isPasswordViewToggled && !isDiscountViewToggled) && (
                    <>
                      {(isRevenueListToggled ? (
                        <DataGrid
                          dataSource={revenue}
                          showBorders={false}
                          height={450}
                        >
                          <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                          <Paging defaultPageSize={10} />
                          <Scrolling mode="standard" />
                          <Column
                            dataField="orderID"
                            caption="Order ID"
                          />
                          <Column
                            dataField="discordID"
                            caption="Discord ID"
                          />
                          <Column
                            dataField="discordUsername"
                            caption="Discord Username"
                          />
                          <Column
                            dataField="profit"
                            caption="Revenue"
                          />
                          <Column
                            dataField="proxyAmount"
                            caption="Proxy Amount"
                            cellRender={renderAmount}
                          />
                          <Column
                            dataField="proxyType"
                            caption="Proxy Type"
                            cellRender={renderType}
                          />
                          <Column
                            dataField="createdAt"
                            caption="Purchase Date"
                            cellRender={renderGridCell}
                          />
                        </DataGrid>
                      )
                        : (
                          <DataGrid
                            dataSource={userList}
                            showBorders={false}
                            height={450}
                          >
                            <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                            <Paging defaultPageSize={10} />
                            <Scrolling mode="standard" />
                            <Column
                              dataField="username"
                              caption="Discord Username"
                            />
                            <Column
                              dataField="user_id"
                              caption="Discord ID"
                            />
                            <Column
                              dataField="proxyCheap"
                              caption="Proxy cheap Data"
                              cellRender={renderProxyCheapData}
                            />
                            <Column
                              dataField="netnutData"
                              caption="Netnut Data Remaining"
                              cellRender={renderNetnutData}
                            />
                            <Column
                              dataField="data_expiry"
                              caption="Expiry Date"
                              cellRender={renderExpiryGridCell}
                            />
                          </DataGrid>
                        )
                      )}
                    </>
                  )}

                </>
              </>
            ) : (
                <DataGrid
                  dataSource={userDashboardData.orders}
                  showBorders={false}
                  height={350}
                >
                  <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                  <Paging defaultPageSize={10} />
                  <Scrolling mode="standard" />
                  <Column
                    dataField="orderID"
                    caption="Order ID"
                  />
                  <Column
                    dataField="proxyAmount"
                    caption="Proxy Amount"
                    cellRender={renderAmount}
                  />
                  <Column
                    dataField="profit"
                    caption="Amount Spent"
                  />
                  <Column
                    dataField="proxyType"
                    caption="Proxy Type"
                    cellRender={renderType}
                  />
                  <Column
                    dataField="createdAt"
                    caption="Purchase Date"
                    // defaultSortOrder="Proxy Type"
                    cellRender={renderGridCell}
                  />


                </DataGrid>
              )}

          </Col>
        </Row>
      </Col>
    </Row>

  );
};

export default User;
