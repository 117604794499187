/* eslint-disable */
import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
    Navbar, Nav, Button, Image,
} from 'react-bootstrap';
import DiscordService from '../../services/DiscordService';
import BlackHatLogo from '../../assets/blackhatlogo.svg';
import ispSrc from '../../assets/svg/solid/Cloud-Server 2.svg';
// import genSrc from '../../assets/svg/Load-Cloud 3.svg';
import genSrc from '../../assets/svg/Earth.svg';
// import genSrc from '../../assets/svg/Network-Add.svg';
// import genSrc from '../../assets/Home.svg';
import getToken from '../../helpers/getToken';


import './_newnav.scss';
import { isJSDocAuthorTag } from 'typescript';

interface IProps {
    showDCPw?: string;
    isAuthenticated?: boolean;
    setIsAuthenticated: any;
    isLoggedIn?: any;
    setIsLoggedIn: any;
}
function makeLogoLink() {
    //return '/'
    if (localStorage.getItem('authToken')) {
        return '/dashboard/user'
    } else {
        return '/'
    }
}

const NewNav: React.FC<IProps> = (props: any) => {
    const {
        isAuthenticated, setIsAuthenticated, isLoggedIn, setIsLoggedIn,
        showDCPw
    } = props;

    const history = useHistory();

    function goShop() {
        let t = getToken()
        history.push('/shop')
        if (t) {
            history.push('/shop')
        } else {
            goDashboard()
        }
    }
    function goto(url) {
        history.push(url)

    }
    function goTerms() {
        history.push('/terms')
    }
    async function doLogout() {
        const onUserLogOutSuccess = () => {
            if (setIsLoggedIn) {
                setIsLoggedIn(false);
            }
            if (setIsAuthenticated) {
                setIsAuthenticated(false);
            }
            localStorage.removeItem('authToken')
            history.push('/');
        };
        const onUserLogOutError = (error: any) => Error(`Logout error: ${error.response}`);

        await DiscordService.UserLogout(onUserLogOutSuccess, onUserLogOutError);
    }
    const goDashboard = async () => {
        const onGetLoginURISuccess = (response: any) => {
            if (response.data) {
                const url = decodeURIComponent(response.data);
                window.location.href = url;
            }
        };
        const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
        await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
    }
    return (
        // <div className="bg-red-500" style={{ backgroundColor: 'red' }}>
        <div>
            <div className="t-text-white page-header t-mt-0 t-w-full rounded-lg">
                <div className="page-inner t-font-bold t-text-2xl">
                    <div className="t-flex t-items-center t-space-x-16">
                        {/* <Nav.Link as={NavLink} exact to="/dashboard/proxies" className="navbar-link" activeStyle={{ borderBottom: '1px solid #fff' }}>
                            Proxies
          </Nav.Link> */}
                        {/* <NavLink to="/"> */}
                        {/* <Link to={makeLogoLink()} style={{ textDecoration: 'none' }}> */}
                        <img onClick={() => goto(makeLogoLink())} className="t-cursor-pointer t-mt-1" src={BlackHatLogo} width="85" />
                        {/* </Link> */}
                        {/* </NavLink> */}
                        {/* <NavLink to="/dashboard/user">
                            <p className="hover:t-text-teal-500 cp">Dashboard</p>
                        </NavLink> */}

                        {/* <Link to="/dashboard/generate" style={{ textDecoration: 'none' }}> */}
                        {/* {isAuthenticated && (

                            <p className="hover:t-text-teal-500 cp" onClick={() => goto("/dashboard/user")}>Dashboard</p>
                        )} */}

                        {isAuthenticated && (
                            // <p className="hover:t-text-teal-500 cp" onClick={() => goto("/dashboard/generate")}>Generate</p>
                            <img onClick={() => goto("/dashboard/generate")} src={genSrc} className="cursor-pointer hover:t-text-teal-500 cp" width="48" />

                        )}

                        {/* {isAuthenticated && (
                            <p className="hover:t-text-teal-500 cp" onClick={() => goto("/dashboard/dc")}>Madhatter ISP</p>
                        )} */}
                        {/* </Link> */}
                        {/* <Link to="/dashboard/dc"> */}
                        {isAuthenticated && (
                            <img onClick={() => goto("/dashboard/dc")} src={ispSrc} className="cursor-pointer hover:t-text-teal-500 cp" width="45" />
                        )}
                        {/* Something */}
                        {/* <p className="hover:t-text-teal-500 cp">ISP</p> */}
                        {/* </Link> */}
                    </div>
                    <div className="t-flex t-items-center t-justify-around t-space-x-6">
                        {/* <!--     <div className="header-cotent"> --> */}
                        {/* <!--       <button className="font-bold cp px text-black py-3 px-8 second rounded-full">Login</button> --> */}
                        {/* {(isLoggedIn)} */}
                        <button onClick={goShop} className="shopbtn font-bold t-bg-gray-600 t-py-3 t-px-8 t-rounded-full t-text-white">Shop</button>
                        {(!isAuthenticated ? (

                            <p onClick={goDashboard} className="hover:t-text-teal-500 cp">Login</p>
                        ) : (
                            <p onClick={doLogout} className="hover:t-text-teal-500 cp">Logout</p>
                        ))}
                        <p onClick={goTerms} className="hover:t-text-teal-500 cp">Terms</p>
                        {/* <!--       <button className="header-btn">Shop</button> --> */}

                        {/* {/lassName="hover:text-teal-500 cp">Shop</p > * /} */}
                        <i className="fab fa-twitter t-text-teal-500"></i>
                    </div>

                    {/* <div className="text-white text-3xl font-bold">Blackhat Proxies</div> */}
                </div>
            </div>


        </div >
    );
};
export default NewNav;
