import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';
import cacheData from '../helpers/cacheData'

export default class UserService {
  static async ReadUser(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discord/user'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  // static async GetLoginURI(onSuccess: any, onError: any) {
  //   await axios
  //     .get(getAPIPath('discord/login'), { withCredentials: true })
  //     .then(onSuccess)
  //     .catch(onError);
  // }

  static async GetLoginURI(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discord/login'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }


  static async GetUser(onSuccess: any, onError: any) {
    // console.log('what the fuck..',localStorage.getItem('authToken'))
    await axios
      .get(getAPIPath('discord/user'), { withCredentials: true })
      .then((resp) => {
        cacheData.getUser = resp;
        onSuccess(resp);
      })
      .catch(onError);
  }

  static async UserLogout(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discord/logout'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async UserAuthenticating(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discord/authenticate'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async AuthenticateUserToGuild(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discord/guilds'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetUserRole(id: string, onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath(`discord/guild/members/${id}`), { withCredentials: true })
      .then((resp) => {
        cacheData.userRole = resp;
        onSuccess(resp);
      })
      .catch(onError);
  }
}
