import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface IProps {
  path?: any;
  hasPermission?: any;
}
const PrivateRoute: React.FC<IProps> = (props: any) => {
  const {
    path,
    hasPermission,
    children,
  } = props;
  return (
    <Route

      path={path}
      render={({ location }) => (hasPermission ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
