/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Nav, Row, Col, Jumbotron, Image, Button, Form, ButtonGroup,
} from 'react-bootstrap';
import './_datacenter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faPlus} from '@fortawesome/free-regular-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import ReactTypingEffect from 'react-typing-effect';
import DiscountService from '../../../services/DiscountService';
import regionList from '../../../helpers/regionList';
import DiscordService from '../../../services/DiscordService';
import ProxyService from '../../../services/ProxyService';
import NetnutService from '../../../services/NetnutService';
import RevenueService from '../../../services/RevenueService';
import ProductService from '../../../services/ProductService';
import UserService from '../../../services/UserService';
import ProxyCheapService from '../../../services/ProxyCheapService';
import DCService from '../../../services/DCService';
import ShService from '../../../services/ShService';
import getStripeKey from '../../../helpers/stripeKey';
import cacheData from '../../../helpers/cacheData'


import countries from '../../../helpers/countries';
import { reverse } from 'dns';

interface IProps {
  userData: any;
}


const Datacenter: React.FC<IProps> = (props: any) => {
  const netnutResi = 'resi';
  const proxyCheapResi = 'resi-non-expiry';

  const { userData } = props;
  const [greeting, setGreeting] = useState('');
  const [greeting2, setGreeting2] = useState('');
  const [activeGroupId, setActiveGroupId] = useState('');
  const [userDashboardData, setUserDashboardData] = useState({}) as any;
  const [netnutDataRemaining, setNetnutDataRemaining] = useState('N/A');
  const [proxyCheapDataRemaining, setProxyCheapDataRemaining] = useState('N/A');
  const [isFetchingDashboardData, setIsFetchingDashboardData] = useState(true);
  const [selectedProxyType, setSelectedProxyType] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [proxyAuthKey, setProxyAuthKey] = useState('');
  const [expirationDate, setExpirationDate] = useState() as any;
  const [regionIndex, setRegionIndex] = useState('');
  const [proxyType, setProxyType] = useState('Sticky');
  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [proxyQuantity, setProxyQuantity] = useState(0);
  const [proxyPool, setProxyPool] = useState('');
  const [regionID, setRegionID] = useState('1');
  const [proxyList, setProxyList] = useState('');
  const [isGenerated, setIsGenerated] = useState(false);
  const [proxyAuthMessage, setProxyAuthMessage] = useState('');
  const [codeInputData, setCodeData] = useState({}) as any;
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [proxyGroups, setProxyGroups] = useState([]) as any;
  // let [proxyGroups, setProxyGroups] = useState([{ count: 5, id: 'someuuid', proxyListCached: ['111.111.111', '222.222.222'] }]);
  let currentGroup = 'Group One';


  const stripeKey: any = getStripeKey()

  const handlePurchase = async (sessionID: string) => {
    const stripe: any = await loadStripe(stripeKey);
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionID,
    });
  };
  function getCodetext() {
    if (codeInputData.name) {
      return "Code Applied!"
    }
    return "Add Code"
  }

  function getDiscountedPrice(price, percent) {
    if (!percent) return price
    var p = (price * (1 - percent / 100)).toFixed(2)
    return p
  }

  function getButtonText(price) {
    // let price = 10
    if (codeInputData.name) {
      return "Renew - " + getDiscountedPrice(price, parseInt(codeInputData.discount))
    }
    return "Renew"
  }

  const reverseShow = () => {
    setShowCodeInput(!showCodeInput)
  }

  const handleRenew = async function (code) {
    return
    console.log(`renewing proxy group ${activeGroupId}`);
    try {
      let grp = proxyGroups.find(r => r.id == activeGroupId)
      let isShopify = grp.shopify == true
      let fn = isShopify ? ShService.genRenewCheckout : DCService.genRenewCheckout
      // let code = null;
      // if (codeInputData && codeInputData.name) {
      //   code = codeInputData.name;
      // }
      const result = await fn(activeGroupId, code);
      console.log('created renew checkout:', result);
      handlePurchase(result.id);
    } catch (e) {
      console.error('error crerating renew checkout:', e);
    }
  };


  const getTotalProxyCount = function () {
    let count = 0;
    for (let i = 0; i < proxyGroups.length; i++) {
      const g = proxyGroups[i];
      count += g.count || 0;
    }
    return count;
  };
  const dosmth = function () {
    console.log('list val changed');
  };

  const getProxyGroups = async function () {
    console.log('getting proxy groups..');
    let resp = await DCService.getUserGroups();
    console.debug('mygroups:', resp);
    let curGroups = proxyGroups
    resp.forEach((x, i) => {
      curGroups.push(x)
    })

    setProxyGroups(curGroups);

    if (!activeGroupId && resp.length > 0) setActiveGroupId(resp[0].id);
    return resp;
  };


  const getProxyGroupsS = async function () {
    console.log('getting proxy groups shopify ..');
    let resp = await ShService.getUserGroups();
    resp = resp.map((x) => {
      x.shopify = true
      return x
    })
    // resp = resp.map(x => x.shopify = true)
    console.debug('mygroups:', resp);
    let curGroups = proxyGroups
    resp.forEach((x, i) => {
      curGroups.push(x)
    })
    setProxyGroups(curGroups);
    if (!activeGroupId && resp.length > 0) setActiveGroupId(resp[0].id);
    return resp;
  };
  function getGroupProp(id, prop) {
    const g = getGroup(id);
    let x;
    if (g && g[prop]) x = g[prop];
    return x;
  }
  function getMetaProp(id, prop) {
    const g = getGroup(id);
    if (!g) return null;
    const { metadata } = g;
    if (!metadata) return null;
    return metadata[prop];
  }
  function buildExp() {
    const g = getGroup(activeGroupId);
    if (!g) return 'N/A';
    const { expiration } = g;
    const d = new Date(expiration);
    const s = d.toLocaleDateString();
    console.log('built exp:', s);
    return s;
  }
  function getGroup(id) {
    if (!id) id = activeGroupId;
    return proxyGroups.find((g) => g.id == id);
  }
  function buildList(arr) {
    if (!arr) return '';
    return arr.join('\n');
  }
  function getProxyList(id) {
    if (!id) id = activeGroupId;
    const group = getGroup(id);
    if (!group) return '';
    const built = buildList(group.proxyListCached);
    return built || 'N/A';
  }
  async function startupDC() {
    console.log('running startup dc function');
    await getAllGroups()
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);
    // await getProxyGroups();
  }
  async function getAllGroups() {

    await getProxyGroups()
    await getProxyGroupsS()
  }

  const textAreaRef = useRef<HTMLDivElement>(null) as any;

  const getUserDetails = async () => {
    const onGetUserRoleSuccess = (response: any) => {
      setUserDashboardData(response.data);
      if (response.data.netnutData) {
        if (response.data.netnutData !== '0B') {
          setSelectedProxyType(netnutResi);
          const newDataString = response.data.netnutData;
          setNetnutDataRemaining(`${newDataString}GB`);
          const date = new Date(response.data.data_expiry);
          const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          setExpirationDate(newDate);
        }
      }

      if (response.data.proxyCheap) {
        if (response.data.proxyCheap.balance > 0) {
          setProxyCheapDataRemaining(`${response.data.proxyCheap.balance / 100}GB`);
          setSelectedProxyType(proxyCheapResi);
          setProxyAuthKey(response.data.proxyCheap.proxyAuthKey);
        }
      }

      setIsFetchingDashboardData(false);
    };

    if (JSON.stringify(cacheData.userRole) != "{}") {
      onGetUserRoleSuccess(cacheData.userRole);
    }
    const onGetUserRoleError = (error: any) => {
      console.log('ERROR: ', error.response);
      setIsFetchingDashboardData(false);
    };
    await DiscordService.GetUserRole(userData.user_id, onGetUserRoleSuccess, onGetUserRoleError);
  };


  useEffect(() => {
    getUserDetails();
  }, []);
  useEffect(() => {
    startupDC();
  }, []);

  const setTimeGreeting = () => {
    const time = new Date().getHours();
    if (time < 10) {
      setGreeting('Good Morning');
    } else if (time < 20) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  };
  useEffect(() => {
    setTimeGreeting();
  }, []);


  if (isFetchingDashboardData) {
    return (
      <div
        style={{
          textAlign: 'center', position: 'relative', top: '50vh',
        }}
      >
        <ReactTypingEffect
          text=". . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>
    );
  }

  const generateSessionIP = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };


  const handleCopyToClipboard = (event: any) => {
    event.preventDefault();
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    event.target.focus();
    setCopyButtonText('Copied!');
    setTimeout(() => {
      setCopyButtonText('Copy');
    }, 2000);
    // setIsCopied(true);
    // setCopySuccess('Copied!');
  };


  return (
    <Row style={{ width: '100%' }}>
      <Col>
        <Jumbotron className="user-jumbotron">
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Col xs="auto">
              <h1 className="user-greeting">
                {greeting}
                ,
                {userDashboardData.username}
              </h1>
            </Col>
            <Col xs="auto">
              <Image
                className="discord-avatar"
                roundedCircle
                src={
                  userDashboardData.avatar
                    ? `https://cdn.discordapp.com/avatars/${userDashboardData.user_id}/${userDashboardData.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${userDashboardData.discriminator % 5}.png`
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>
                Total Proxy Groups:
                {' '}
                {proxyGroups.length}
              </h6>
              <h6>
                Total Proxies:
                {' '}
                {getTotalProxyCount()}
              </h6>
            </Col>
          </Row>

          <Row style={{ justifyContent: 'flex-end' }}>
            <Col xs="auto">
              {proxyAuthMessage && (
                <h6>
                  {proxyAuthMessage}
                </h6>
              )}
            </Col>
          </Row>


        </Jumbotron>
        <Row noGutters style={{ marginTop: '2rem' }}>
          {proxyGroups.length > 1 && (
            <Col>
              <ButtonGroup size="lg" aria-label="Basic example">
                {proxyGroups.map((g, index) => (
                  <Button
                    key={index}
                    className={(activeGroupId == g.id) ? 'purchase-button' : ''}
                    variant="secondary"
                    onClick={() => {
                      setActiveGroupId(g.id);
                    }}
                  >
                    {`Group ${index + 1}`}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          )}
          {/* <Col>
            <ButtonGroup size="lg" aria-label="Basic example">
                {/*disabled={userDashboardData.netnutData ? (!userDashboardData.netnutData || userDashboardData.netnutData === 0) : true}
              <Button
                className={(selectedProxyType === netnutResi) ? 'purchase-button' : 'purchase-button'}
                variant="secondary"
                onClick={() => {
                  setSelectedProxyType(netnutResi);
                }}
              >
                Group 1

              </Button>
              <Button
                disabled={userDashboardData.proxyCheap ? (!userDashboardData.proxyCheap.balance || userDashboardData.proxyCheap.balance === 0) : true}
                className={(selectedProxyType === proxyCheapResi) ? 'purchase-button' : ''}
                variant="secondary"
                onClick={() => {
                  setSelectedProxyType(proxyCheapResi);
                }}
              >
                Group 2
              </Button>

            </ButtonGroup>
          </Col> */}
        </Row>

        <Row noGutters style={{ marginTop: '2rem' }}>
          <Col xs={12}>
            {/* <Form style={{ textAlign: 'left', fontSize: '1.5rem' }}>

              <Row style={{ alignItems: 'flex-end', justifyContent: 'center' }}>

                <Col xs={{ span: 3 }}>
                  <Form.Label>Proxy Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter proxy quantity"
                    value={proxyQuantity}
                    onChange={(event: any) => {
                      setProxyQuantity(event.target.value);
                    }}
                  />
                </Col>

                <Col xs={3}>
                  <Form.Label>Proxy Group</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(event: any) => {
                      setProxyType(event.target.value);
                    }}
                    value={proxyType}
                  >
                    <option>Sticky</option>
                    <option>Rotating</option>
                  </Form.Control>
                </Col>

                <Col xs={3}>
                  <Form.Label>Proxy Pool</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(event: any) => {
                      if (selectedProxyType === netnutResi) {
                        console.log('EVENT: ', event.target.value);
                        if (event.target.value.toLowerCase() === 'release') {
                          setProxyPool(event.target.value);
                          setRegionIndex(event.target.value);
                        } else {
                          setProxyPool(regionList[event.target.value]);
                          setRegionIndex(event.target.value);
                          setRegionID(regionList[event.target.value]);
                        }
                      } else {
                        setProxyPool(event.target.value);
                        setRegionIndex(event.target.value);
                      }
                    }}
                    value={regionIndex}
                  >
                    {selectedProxyType === netnutResi ? (
                      <>
                        <option label="" value="" />
                        <option label="Release" value="Release">Release</option>
                        {regionList.map((region, index) => (
                          <>
                            <option label={region} value={index}>{region}</option>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <option label="" value="" />
                        {countries.map((country, index) => (
                          <>
                            <option value={country}>{country}</option>
                          </>
                        ))}
                      </>
                    )}

                  </Form.Control>
                </Col>

                <Col>
                  {/* <Button
                    variant="primary"
                    className="generate-button"
                    type="submit"
                    onClick={handleGenerate}
                  >
                    GENERATE
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="generate-button"
                    type="submit"
                    onClick={handleCopyToClipboard}
                  >
                    COPY
                  </Button>
                </Col>


              </Row>
            </Form>
            */}

            {/* <Row style={{ alignItems: 'flex-end', justifyContent: 'center' }}>  */}
            {proxyGroups.length > 0
              ? (

                <div className="pxDetails">
                  <p style={{ fontSize: '1.2rem' }}>
                    Proxies in Group:
                    {' '}
                    {getGroupProp(activeGroupId, 'count')}
                  </p>
                  <p style={{ fontSize: '1.2rem' }}>
                    {' '}
                    Price: $
                    {getMetaProp(activeGroupId, 'price') || 0}
                  </p>
                  <p style={{ fontSize: '1.2rem' }}>
                    Expiration:
                    {' '}
                    {buildExp()}
                  </p>
                  {/* <div className="t-mt-1"> */}
                  <Button disabled
                    className="generate-button mt-4 renewBtn"
                    variant="primary"
                    onClick={() => handleRenew(codeInputData.name)}
                  >
                    {getButtonText(getMetaProp(activeGroupId, 'price'))}
                  </Button>
                  <div onClick={reverseShow} className="mt-2 cursor-pointer" style={{ cursor: 'pointer' }}><span>
                    <FontAwesomeIcon color="#f5f5f5" icon={faPlus} />
                  </span>

                    <span className="ml-2">{getCodetext()}</span>
                  </div>
                  {showCodeInput
                    ? (
                      <div className="mt-2">
                        <Form.Control
                          type="text"
                          placeholder="Discount Code"
                          onChange={(event: any) => {
                            var val = event.target.value;
                            const isValidCode = validDiscountCodes.find((discountCode) => {
                              let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                              if (isDc && discountCode.name.toLowerCase() === val.toLowerCase()) {
                                // alert('valid code boys.')
                                setCodeData(discountCode)
                                // setShowCodeInput(val)
                                reverseShow()
                                // setDiscountBeingUsedResi(discountCode);
                                return discountCode;
                              }
                            });
                            // setProxyQuantity(event.target.value);
                          }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                </div>
                // </div>
              )
              : (
                <div>
                  <Nav.Link as={NavLink} exact to="/shop" className="black-hat-header-mini" style={{ marginBottom: '2rem', fontSize: '2rem' }}>Buy Proxies?</Nav.Link>
                </div>
              )}

            <Row style={{ marginTop: '2rem', textAlign: 'left' }}>
              <Col xs={12}>
                <div className="something">
                  <Form.Label style={{ fontSize: '2rem' }}>
                    <span>Proxy List</span>
                  </Form.Label>
                  <div>

                    <Button
                      variant="primary"
                      className="generate-button"
                      type="submit"
                      onClick={handleCopyToClipboard}
                    >
                      {copyButtonText}
                    </Button>
                  </div>
                </div>
                <Form.Group>
                  {/* <Form.Label style={{ fontSize: '2rem' }}>
                    <span>
                    Proxy List
                    </span>

                    {isCopied && (
                    <h6>Copied!</h6>
                    )}
                  </Form.Label> */}
                  <Form.Control className="list-area t-border-none" style={{ color: 'white' }} as="textarea" rows={15} onChange={dosmth} value={getProxyList('')} ref={textAreaRef} />
                </Form.Group>
              </Col>
            </Row>
          </Col>

        </Row>
      </Col>
    </Row>

  );
};

export default Datacenter;
