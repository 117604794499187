/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import {
  Nav, Row, Col, Jumbotron, Button, Card, ListGroup, ListGroupItem, Form, Modal, ButtonGroup,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import Stripe from '../../Stripe/DCStripe';
import ProductService from '../../../services/ProductService';
import DiscordService from '../../../services/DiscordService';
import DiscountService from '../../../services/DiscountService';
import ProxyCheapService from '../../../services/ProxyCheapService';
import DCService from '../../../services/DCService';

interface IProps {
  userData: any;
  isAuthenticated: any;
}

const Proxy: React.FC<IProps> = (props: any) => {
  const { userData, isAuthenticated } = props;
  // const [selectedProduct, setSelectedProduct] = useState() as any;
  // const [productIndex, setProductIndex] = useState();
  const [planQty, setPlanQty] = useState(25)
  const [timespan, setTimespan] = useState(null)
  const [qtyPlans, setPlans] = useState({})
  const [isInStock, setisInstock] = useState(true)
  const [stockData, setStockData] = useState({})
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [show, setShow] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [discountValue, setDiscountValue] = useState('');
  const [validDiscount, setValidDiscount] = useState(false);
  const [discountBeingUsed, setDiscountBeingUsed] = useState({} as any);

  let baseDescription = 'Black Hat Datacenter Proxies - Non Refundable. Digital Goods Delivered Upon Payment'

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function getDiscountedPrice(price, percent) {
    if (!percent) return price
    var p = (price * (1 - percent / 100)).toFixed(2)
    return p
  }
  function createLabel(key) {
    var planPrice = qtyPlans[key]
    var price = validDiscount ? getDiscountedPrice(planPrice, discountBeingUsed.discount) : planPrice
    return `${key} - $${price}`
  }
  function getPlanKeys() {
    return Object.keys(qtyPlans)
  }
  function getSelectedProduct() {
    let planPrice = qtyPlans[planQty]
    let p = {
      count: planQty,
      amount: planPrice,
      type: 'dc',
      // amount:getDiscountedPrice(planPrice,discountBeingUsed.discount),
      name: 'Datacenter Proxies',
    }
    console.log(p)
    return p
  }



  const getProduct = async () => {
    let stockData = await DCService.getPlans();
    console.log(`got stock data:`, stockData)
    setStockData(stockData);
    let { inStock, reserved, total, plans } = stockData
    setisInstock(inStock)
    setPlans(plans)
    setIsFetchingProducts(false);
  }


  useEffect(() => {
    getProduct();
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);



  }, []);



  const handlePurchase = async () => {
    if (isAuthenticated) {
      handleShow()
    } else {
      const onGetLoginURISuccess = (response: any) => {
        if (response.data) {
          const url = decodeURIComponent(response.data);
          window.location.href = url;
        }
      };
      const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
      await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
    }
  };

  useEffect(() => {

  }, []);


  if (isProcessingPayment) {
    return (
      <div style={{ top: '50%', position: 'relative' }}>
        <ReactTypingEffect
          text="Processing . . ."
          speed={100}
          eraseDelay={500}
          typingDelay={0}
          className="splash-loader"
        />
      </div>

    );
  }


  return (
    <Row>
      <Col>
        <h1 className="black-hat-header" style={{ marginTop: '5rem' }}>Datacenter Proxies</h1>
        <h4 style={{ fontWeight: 500 }}>All sales are final, and non-refundable.</h4>
        {/*<ButtonGroup size="lg" aria-label="Basic example">
          <Button
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`some button clcked`);
              // setSelectedProxyType(proxiwareResi);
            }}
          >
            60-Day Plan

          </Button>
          <Button
            disabled={false}
            className='purchase-button'
            variant="secondary"
            onClick={() => {
              console.log(`other button clicked non exp`)
            }}
          >
            Non-Expiry Plan

          </Button>
        </ButtonGroup> */}
        {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
        <Row style={{ justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem' }}>

          {isFetchingProducts ? (
            <ReactTypingEffect
              text="Fetching Products . . ."
              speed={100}
              eraseDelay={500}
              typingDelay={0}
              className="splash-loader"
            />
          ) : (
              <Card className="purchase-card">
                <Card.Img variant="top" src="" />
                <Card.Body>
                  <Card.Title />
                  {/*<Card.Text> */}
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <h1>30 Day Plan</h1>
                    {/*<Form.Label className={hasChosenProduct ? 'text' : 'error-text'}>Please select your proxy package</Form.Label> */}
                    <Form.Label className={true ? 'text' : 'error-text'}>Please select your proxy package.</Form.Label>
                    <Form.Control
                      as="select"
                      value={planQty || ''}
                      onChange={(event: any) => {
                        console.log(`form control changed`)
                        console.log(event.target.value)
                        setPlanQty(event.target.value)
                      }}
                    >
                      <option value="" disabled>Select Quantity</option>
                      {getPlanKeys().map((key, index) => (
                        <option key={index} label={createLabel(key)} value={key}>{key}</option>
                      ))}

                    </Form.Control>
                  </Form.Group>
                  {/*</Card.Text> */}
                </Card.Body>
                <ListGroup className="list-group-flush purchase-list-group">
                  <ListGroupItem>
                    <Form.Label>
                      {validDiscount && (
                        'Discount has been applied!'
                      )}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={discountValue}
                      onChange={(event: any) => {
                        setDiscountValue(event.target.value);
                      }}
                      onKeyPress={(event: any) => {
                        if (event.charCode === 13) {
                          const isValidCode = validDiscountCodes.find((discountCode) => {
                            let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                            if (isDc && discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                              setDiscountBeingUsed(discountCode);
                              return discountCode;
                            }
                          });
                          if (isValidCode) {
                            setValidDiscount(true);
                          } else {
                            setValidDiscount(false);
                          }
                        }
                      }}
                      onBlur={() => {
                        const isValidCode = validDiscountCodes.find((discountCode) => {
                          let isDc = discountCode.worksOn == 'both' || discountCode.worksOn == 'dc'
                          if (isDc && discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                            setDiscountBeingUsed(discountCode);
                            return discountCode;
                          }
                        });
                        if (isValidCode) {
                          setValidDiscount(true);
                        } else {
                          setValidDiscount(false);
                        }
                      }}
                      placeholder="Enter discount code here"
                    />

                  </ListGroupItem>
                  <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                  {/*<ListGroupItem>STATIC AND ROTATING PROXIES</ListGroupItem> */}
                  <ListGroupItem>FOOTSITES, SUPREME, SHOPIFY, YEEZYSUPPLY</ListGroupItem>
                </ListGroup>
                <Card.Body>

                  <Button
                    className="purchase-button"
                    onClick={handlePurchase}
                    disabled={!isInStock}
                  >
                    {!!isInStock ? "PURCHASE" : "SOLD OUT"}
                  </Button>
                </Card.Body>
              </Card>


            )}


        </Row>
        <Modal show={show} centered onHide={handleClose} className="proxy-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              {hasAgreedToTerms ? (
                <>
                  <p>PURCHASE NOW</p>
                  <p style={{ fontSize: '1rem' }}>{baseDescription}</p>
                </>
              ) : (<p>TERMS OF SALE</p>)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <PaypalButton selectedProduct={selectedProduct} discountAmount={discountBeingUsed.discount} discountValue={discountValue} userData={userData} setIsProcessingPayment={setIsProcessingPayment} setPaymentSuccess={setPaymentSuccess} /> */}
            {hasAgreedToTerms ? <Stripe selectedProduct={getSelectedProduct()} userData={userData} isAuthenticated={isAuthenticated} discountAmount={discountBeingUsed.discount} discountValue={discountValue} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
          </Modal.Body>
          <Modal.Footer>
            {hasAgreedToTerms ? (
              <Button
                variant="primary"
                className="purchase-button"
                onClick={() => {
                  handleClose();
                  setHasAgreedToTerms(false);
                }}
              >
                CANCEL
              </Button>
            ) : (
                <Button variant="primary" className="purchase-button" onClick={() => { setHasAgreedToTerms(true); }}>
                  I AGREE
                </Button>
              )}
          </Modal.Footer>
        </Modal>

        <Nav.Link as={NavLink} exact to="/dashboard/proxies" className="black-hat-header-mini" style={{ marginBottom: '2rem', fontSize: '2rem' }}>Buy Residential?</Nav.Link>
      </Col>
    </Row>
  );
}

export default Proxy;
