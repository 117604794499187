import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class ProductService {
  static async GetProducts(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('products/get'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetResidentialInventory(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('/products/inventory/residential'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }
}
