/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import {
  Nav, Row, Col, Jumbotron, Button, Card, ListGroup, ListGroupItem, Form, Modal, ButtonGroup,
} from 'react-bootstrap';
import axios from 'axios';
import ReactTypingEffect from 'react-typing-effect';

interface IProps {
  userData: any;
  isAuthenticated: any;
}

const Proxy: React.FC<IProps> = (props: any) => {
  const { userData, isAuthenticated } = props;

  let baseDescription = 'Black Hat Datacenter Proxies - Non Refundable. Digital Goods Delivered Upon Payment'
  return (
    <Row>
      <Col>
        {/* <h1 className="black-hat-header bg-red-500" style={{ marginTop: '5rem' }}>Datacenter Invoice</h1> */}

        <div>
          <h1>Terms of Service</h1>
          <p className="t-text-2xl t-mt-8 t-text-left">
            By purchasing anything on our website you agree that all sales are Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.
</p>

        </div>
      </Col>
    </Row>
  );
}

export default Proxy;
