/*eslint-disable */
import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
    Button, Container, Row, Col, Image,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import NavbarWrapper from '../Navbar/NavbarWrapper';
// import logo from '../../logo.svg';
import BlackHatLogo from '../../assets/blackhatlogo.svg';
import './_splash.scss';
import '../../App.scss';

import lockImg from '../../assets/svg/Lock-1.svg'
import rotateImg from '../../assets/svg/Cloud-Server 1.svg'

interface IProps {
}

const Features: React.FC<IProps> = (props: any) => {
    // const history = useHistory();

    return (
        <div>

            <div className="t-pb-1 t-text-center t-text-white t-bg-cover">
                {/* soething */}
                <div className="t-my-8">
                    <div className="t-max-w-xl t-px-4 t-mx-auto sm:t-px-6 lg:t-max-w-screen-xl lg:t-px-8">
                        <div className="lg:t-grid lg:t-grid-cols-3 lg:t-gap-12">
                            <div className="feature-box t-p-16 t-transition-all t-duration-150 t-rounded-lg t-shadow-xl t-ease hover:t-shadow-2xl">
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 t-h-16 t-overflow-hidden t-text-white t-rounded-full"
                                >
                                    <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        />
                                        {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                        </path> */}
                                    </svg>
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6 t-text-white">Data Never Expires!</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6 t-text-white">
                                        Your Residential plan data will last until you use every last MB.
                      </p>
                                </div>
                            </div>
                            <div
                                className="feature-box t-p-16 t-mt-10 t-transition-all t-duration-150 t-rounded-lg t-shadow-xl lg:t-mt-0 t-ease hover:t-shadow-2xl"
                            >
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 t-h-16 t-overflow-hidden t-text-white t-rounded-full"
                                >
                                    {/* <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                        />
                                    </svg> */}
                                    <img src={rotateImg} width="48" />
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6">Rotating & Sticky Proxies</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6">
                                        When generating proxies you can choose between rotating or sticky sessions!
                      </p>
                                </div>
                            </div>
                            <div
                                className="feature-box t-p-16 t-mt-10 t-transition-all t-duration-150 feature-box t-rounded-lg t-shadow-xl lg:t-mt-0 t-ease hover:t-shadow-2xl"
                            >
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 h-16 t-overflow-hidden text-white rounded-full"
                                >
                                    {/* SVG */}

                                    {/* <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                                        />
                                    </svg> */}
                                    <img src={lockImg} width="48" />
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6">User:Pass Auth</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6">
                                        Both our Residential & ISP plans are authenticated via USER:PASS auth.
                      </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="t-my-8">
                    <div className="t-max-w-xl t-px-4 t-mx-auto sm:t-px-6 lg:t-max-w-screen-xl lg:t-px-8">
                        <div className="lg:t-grid lg:t-grid-cols-3 lg:t-gap-12">
                            <div className="feature-box bg-opacity-25 t-p-16 t-transition-all t-duration-150 t-rounded-lg t-shadow-xl t-ease hover:t-shadow-2xl">
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 t-h-16 t-overflow-hidden t-text-white t-rounded-full"
                                >
                                    <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6">Discord Support</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6">
                                        After purchasing, you will automatically be added to our discord where we offer support should you need it!
                      </p>
                                </div>
                            </div>
                            <div
                                className="feature-box t-p-16 t-mt-10 t-transition-all t-duration-150 t-rounded-lg t-shadow-xl lg:t-mt-0 t-ease hover:t-shadow-2xl"
                            >
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 t-h-16 t-overflow-hidden t-text-white t-rounded-full"
                                >
                                    <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6">Country & Site Specific Proxies</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6">
                                        Choose between 228 residential proxy pools of countries & stores.
                      </p>
                                </div>
                            </div>
                            <div
                                className="feature-box t-p-16 t-mt-10 t-transition-all t-duration-150 t-rounded-lg t-shadow-xl lg:t-mt-0 t-ease hover:t-shadow-2xl"
                            >
                                <div
                                    className="t-relative t-inline-flex t-items-center t-justify-center t-w-16 t-h-16 t-overflow-hidden t-text-white t-rounded-full"
                                >
                                    <svg
                                        className="t-relative t-w-12 t-h-12 t-text-teal-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div className="t-mt-3 t-mb-6">
                                    <h5 className="t-pb-2 t-text-xl t-font-bold t-leading-6">DM Bot</h5>
                                    <p className="t-mt-1 t-text-lg t-leading-6">
                                        Need to get your ISP list without opening our site? Just send "!isp list" to our custom-built customer bot.
                      </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Features;