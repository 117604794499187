/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Image } from 'react-bootstrap';
import DiscordService from './services/DiscordService';
import Routes from './Routes/Routes';
import './App.scss';
import './Components/_loader.scss';
import BlackHatLogo from './assets/blackhatlogo.svg';
import SubService from './services/SubWorks';
import config from './config'
import axiosAuth from './helpers/axiosAuth'
import cacheData from './helpers/cacheData'
axiosAuth()
let { baseURL } = config

axios.defaults.baseURL = baseURL


const App: React.FC = () => {
  const [userData, setUserData] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [newMemberHasPurchased, setNewMemberHasPurchased] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [finishedAnimation, setFinishedAnimation] = useState(false);
  const history = useHistory();
  const getUserDetails = async () => {
    SubService.getPools()
      .then(() => {
        console.log(`got pools and applied to window`)
      })
    // componentDidMount = () => {
    //   console.log('did mount')
    // }

    const onGetUserSuccess = async (response: any) => {
      setIsLoggedIn(true);
      // cacheData.getUser = response.data
      if (response.data.role) {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
        setUserData(response.data);
        history.push('/dashboard/user');
        return;
      }
      setUserData(response.data);
      const onGetUserRoleSuccess = (response: any) => {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
        setUserData(response.data);
        // cacheData.userRole = response.data
        if (history.location.pathname === '/' || history.location.pathname === '/authenticated') {
          history.push('/dashboard/user');
        }
      };
      const onGetUserRoleError = (error: any) => {
//localStorage.removeItem("authToken")
        if (response.data.role) {
          setIsAuthenticated(true);
          setIsAuthenticating(false);
          console.log('set authed from 59 aptsx')
          setUserData(response.data);
          history.push('/dashboard/user');
          return;
        } else {
          setIsAuthenticated(false)
        }
        setIsAuthenticating(false);
        if (history.location.pathname === '/dcpassword') {
        } else if (history.location.pathname === '/invoice') {
          console.log(`on invoice page... (user role error)`)
        }
        else {
          history.push('/');
        }
        // console.log('GET USER ROLE ERROR: ', error.response);
      };
      await DiscordService.GetUserRole(response.data.id, onGetUserRoleSuccess, onGetUserRoleError);
    };
    const onGetUserError = async (error: any) => {
localStorage.removeItem("authToken")
      setIsAuthenticating(false);
      setIsAuthenticated(false)
      console.log('GET USER ERROR: ', error);
      if (history.location.pathname === '/invoice') {
        console.log(`on invoice page... redirecting to login...`)
        const onGetLoginURISuccess = (response: any) => {
          if (response.data) {
            const url = decodeURIComponent(response.data);
            window.location.href = url;
          }
        };
        const onGetLoginURIError = (error: any) => Error(`Login error: ${error.response}`);
        await DiscordService.GetLoginURI(onGetLoginURISuccess, onGetLoginURIError);
        history.push('/dashboard/user')
      }
    };
    // this code all over literally sucks

    if (history.location.pathname === '/' || history.location.pathname === '/authenticated') {
      var urlParams = new URLSearchParams(window.location.search);
      let t = urlParams.get('token')
      // console.log(`user authenticated with token ${t}`)
      if (t) {
        localStorage.setItem('authToken', t)
      } else {
        console.log(`user not authenticated`)

      }
      history.push('/dashboard/user');
    }
    await DiscordService.GetUser(onGetUserSuccess, onGetUserError);
  };

  useEffect(() => {
    getUserDetails();
  }, [newMemberHasPurchased, isAuthenticating]);


  // useEffect(() => {
  //   setTimeout(() => {
  //     setFinishedAnimation(true);
  //   }, 2500);
  // }, []);

  // if (isAuthenticating || !finishedAnimation) {
  //   return (
  //     <>
  //       <div className="container-loader">
  //         <div className="glitch">
  //           <Image src={BlackHatLogo} className="App-logo" alt="logo" />
  //         </div>
  //         <div className="glow" />
  //         <p className="subtitle">Blackhat Proxies</p>
  //       </div>
  //       <div className="scanlines" />
  //     </>
  //   );
  // }


  return (
    <>
      <div className=""></div>
      <Routes isAuthenticated={isAuthenticated} userData={userData} setIsAuthenticated={setIsAuthenticated} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setNewMemberHasPurchased={setNewMemberHasPurchased} />
      {/* <div>
        footer
      </div> */}
    </>
  );
};

export default App;
