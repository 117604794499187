import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class ProxyCheapService {
  static async ResetProxyAuthKey(data: any, onSuccess: any, onError: any) {
    await axios
      .post(getAPIPath('/proxy/auth/reset'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetRemainingTotalData(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('/proxy/data'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }
}
