import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class PasswordService {
  static async Generate(data: any, onSuccess: any, onError: any) {
    await axios
      .post(getAPIPath('password/generate'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetValid(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('/password/get/valid'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async UpdateRow(data: any, onSuccess: any, onError: any) {
    await axios
      .put(getAPIPath('/password/update'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async RemoveRow(id: any, onSuccess: any, onError: any) {
    await axios
      .delete(getAPIPath(`password/${id}/remove`), {
        withCredentials: true,
      })
      .then(onSuccess)
      .catch(onError);
  }

  static async DecreaseUses(id: any, onSuccess: any, onError: any) {
    await axios
      .put(getAPIPath(`password/${id}/decrease`), {
        withCredentials: true,
      })
      .then(onSuccess)
      .catch(onError);
  }
}
