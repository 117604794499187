import React, { useState, useEffect } from 'react';
import {
  Row, Col, Container, Button, Form, Card, ListGroup, ListGroupItem, Modal, ButtonGroup,
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ProductService from '../../services/ProductService';
import DiscountService from '../../services/DiscountService';
import PasswordService from '../../services/PasswordService';
import Stripe from '../Stripe/Stripe';
import PaypalButton from '../Paypal/paypalButton';
import NavbarWrapper from '../Navbar/NavbarWrapper';

interface IProps {
  userData: any;
  isLoggedIn: boolean;
  setIsLoggedIn: any;
  setIsAuthenticated: any;
  setNewMemberHasPurchased: any;
}
const PasswordPage: React.FC<IProps> = (props: any) => {
  const {
    userData, isLoggedIn, setIsLoggedIn, setIsAuthenticated, setNewMemberHasPurchased,
  } = props;

  const proxiwareResi = 'resi';
  const proxyCheapResi = 'resi-non-expiry';
  const proxyWorksResi = 'resi-works';

  // const passwordRegex = /tidalrents|cooking20/gmi;
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [proxiwareProducts, setProxiwareProducts] = useState([]) as any;
  const [proxyCheapProducts, setProxyCheapProducts] = useState([]) as any;
  const [proxyWorksProducts, setProxyWorksProducts] = useState([]) as any;
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [productIndex, setProductIndex] = useState();
  const [selectedProxyType, setSelectedProxyType] = useState(proxyWorksResi) as any;
  const [selectedProduct, setSelectedProduct] = useState() as any;
  const [show, setShow] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [hasChosenProduct, sethasChosenProduct] = useState(true);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const [validDiscountCodes, setValidDiscountCodes] = useState([] as any);
  const [discountValue, setDiscountValue] = useState('');
  const [validDiscount, setValidDiscount] = useState(false);
  const [discountBeingUsed, setDiscountBeingUsed] = useState({} as any);


  const [validPasswords, setValidPasswords] = useState([] as any);
  const [validPassword, setValidPassword] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const nameFromId = id => {
    let x = validPasswords.find(x => x._id == id)
    if (!x) return x
    return x.name
  }
  function shouldShowInput() {
    let x = getQueryPw()
    if (isValidPassword) return false
    if (!isValidPassword && !x) return true
    if (!isValidPassword && x && validPasswords.length == 0) {
      // console.log(`should not show pw input`,66)
      return false
    }
    return true
  }

  const getQueryPw = () => {
    var urlParams = new URLSearchParams(window.location.search);
    let pwVal = urlParams.get('x')
    return pwVal || ''
  }

  const handlePassword = (event: any, data: any) => {

    if (event.charCode === 13) {
      if (!data) data = validPasswords
      // if (event.target.value.match(passwordRegex)) {
      //   setIsValidPassword(true);
      // } else {
      //   setIsValidPassword(false);
      // }
      data.find((password) => {
        const passwordRegex = new RegExp(password.name, 'gmi');
        if (event.target.value.match(passwordRegex)) {
          setIsValidPassword(true);
          setValidPassword(password._id);
          return password;
        }
        setIsValidPassword(false);
      });
    }
  };

  const getProduct = async () => {
    const onGetProductSuccess = (response: any) => {
      let wp: any = []
      if (response.data) {
        response.data.forEach((product) => {
          if (product.type === proxiwareResi) {
            setProxiwareProducts((oldProxiwareState: any) => [...oldProxiwareState, product]);
          } else if (product.type === proxyCheapResi) {
            setProxyCheapProducts((oldProxyCheapState: any) => [...oldProxyCheapState, product]);
          } else if (product.type == proxyWorksResi) {
            wp.push(product)
            // console.log(product,6999)
            setProxyWorksProducts((old: any) => [...old, product]);
          }
        });
        if (wp.length > 0) setSelectedProduct(wp[0])
        setIsFetchingProducts(false);
      }
    };
    const onGetProductError = (error: any) => {
      console.log('ERROR FETCHING PRODUCTS: ', error);
    };

    await ProductService.GetProducts(onGetProductSuccess, onGetProductError);
  };

  useEffect(() => {
    getProduct();
    const onGetValidDiscountSuccess = (response: any) => {
      setValidDiscountCodes([...response.data]);
    };
    const onGetValidDiscountError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    DiscountService.GetValid(onGetValidDiscountSuccess, onGetValidDiscountError);

    const onGetValidPasswordSuccess = (response: any) => {
      setValidPasswords([...response.data]);
      let pwVal = getQueryPw()
      if (pwVal) {
        console.log(`pw startup fn with id ${pwVal}`);
        let x = { charCode: 13, target: { value: pwVal } }
        handlePassword(x, response.data)
      }
    };
    const onGetValidPasswordError = (error: any) => {
      console.log('ERROR GETTING DISCOUNT CODES: ', error.response);
    };
    PasswordService.GetValid(onGetValidPasswordSuccess, onGetValidPasswordError);
  }, []);

  const handlePurchase = async () => {
    if (selectedProduct) {
      sethasChosenProduct(true);
      handleShow();
    } else {
      sethasChosenProduct(false);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.type === proxiwareResi) {
        setSelectedProxyType(proxiwareResi);
      } else if (selectedProduct.type === proxyCheapResi) {
        setSelectedProxyType(proxyCheapResi);
      } else if (selectedProduct.type === proxyWorksResi) {
        setSelectedProxyType(proxyWorksResi)
      }
    }
  }, [selectedProduct]);


  return (
    <Container className="App splash-background" fluid>
      {isProcessingPayment ? (
        <div style={{ top: '50%', position: 'relative' }}>
          <ReactTypingEffect
            text="Processing . . ."
            speed={100}
            eraseDelay={500}
            typingDelay={0}
            className="splash-loader"
          />
        </div>
      ) : (
          <>
            <Container>
              <NavbarWrapper showDCPw={nameFromId(validPassword)} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsAuthenticated={setIsAuthenticated} />
            </Container>
            <Row noGutters className="App-body black-hat-splash" style={{ bottom: '1.5rem', position: 'relative' }}>
              <Row className="splash-row">

                <Col xs={12}>
                  {shouldShowInput() ? (
                    <>
                      <Row style={{ justifyContent: 'center', height: '3.5rem', marginBottom: '2rem' }}>
                        <ReactTypingEffect
                          text="Password Page"
                          speed={100}
                          typingDelay={0}
                          className="black-hat-header"
                        />
                      </Row>
                      <Row>
                        <Col xs="auto">
                          <h2 className="black-hat-paragraph">Please enter the password in order to purchase a proxy plan.</h2>

                        </Col>
                      </Row>
                      <Row style={{ justifyContent: 'center', marginTop: '5rem' }}>
                        <Col xs={4}>
                          <Form.Control placeholder="" onKeyPress={(event: any) => {
                            handlePassword(event, null)
                          }} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                      <>
                        <Row>
                          <Col>
                            <h1 className="black-hat-header" style={{ marginTop: '5rem' }}>Residential Proxies</h1>
                            <h4 style={{ fontWeight: 500 }}>All sales are final, and non-refundable.</h4>
                            {/*<ButtonGroup size="lg" aria-label="Basic example">
                              <Button
                                className={(selectedProxyType === proxiwareResi) ? 'purchase-button' : ''}
                                variant="secondary"
                                onClick={() => {
                                  setSelectedProxyType(proxiwareResi);
                                  setSelectedProduct('');
                                }}
                              >
                                60-Day

                          </Button>
                              <Button
                                className={(selectedProxyType === proxyCheapResi) ? 'purchase-button' : ''}
                                variant="secondary"
                                onClick={() => {
                                  setSelectedProxyType(proxyCheapResi);
                                  setSelectedProduct('');
                                }}
                              >
                                MagicHatter Plan

                          </Button>

                              {/*<Button
                            className={(selectedProxyType === 'dc') ? 'purchase-button' : ''}
                            variant="secondary"
                            onClick={() => {
                              console.log(`going to dc page`)
                            }}
                          >
                          ISP

                          </Button>
                            </ButtonGroup> */}
                            {paymentSuccess ? null : (<h2 className="error-text">Error Processing Payment. Please try purchasing again.</h2>)}
                            <Form>
                              <Row style={{ justifyContent: 'center', marginTop: '2rem' }}>

                                {isFetchingProducts ? (
                                  <ReactTypingEffect
                                    text="Fetching Products . . ."
                                    speed={100}
                                    eraseDelay={500}
                                    typingDelay={0}
                                    className="splash-loader"
                                  />
                                ) : (
                                    <>
                                      {selectedProxyType === proxyWorksResi ? (
                                        <Card className="purchase-card">
                                          <Card.Img variant="top" src="" />
                                          <Card.Body>
                                            <Card.Title />
                                            <Card.Text>
                                              <Form.Group controlId="exampleForm.ControlSelect2">
                                                <h1>MagicHatter Plan</h1>
                                                <Form.Label className={hasChosenProduct ? 'text' : 'error-text'}>Please select your proxy package</Form.Label>

                                                <Form.Control
                                                  as="select"
                                                  value={productIndex}
                                                  onChange={(event: any) => {
                                                    setSelectedProduct(proxyWorksProducts[event.target.value]);
                                                    setProductIndex(event.target.value);
                                                  }}
                                                >
                                                  <option value="" disabled>Select Plan</option>
                                                  {proxyWorksProducts.map((product, index) => (
                                                    <option label={`${product.name} - $${validDiscount ? ((product.amount * (1 - discountBeingUsed.discount / 100)).toFixed(2)) : product.amount}`} value={index}>{product.name}</option>
                                                  ))}
                                                </Form.Control>
                                              </Form.Group>
                                            </Card.Text>
                                          </Card.Body>
                                          <ListGroup className="list-group-flush purchase-list-group">
                                            <ListGroupItem>
                                              <Form.Label>
                                                {validDiscount && (
                                                  'Discount has been applied!'
                                                )}
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                value={discountValue}
                                                onChange={(event: any) => {
                                                  setDiscountValue(event.target.value);
                                                }}
                                                onKeyPress={(event: any) => {
                                                  if (event.charCode === 13) {
                                                    event.preventDefault();
                                                    const isValidCode = validDiscountCodes.find((discountCode) => {
                                                      if (discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                                                        setDiscountBeingUsed(discountCode);
                                                        return discountCode;
                                                      }
                                                    });
                                                    if (isValidCode) {
                                                      setValidDiscount(true);
                                                    } else {
                                                      setValidDiscount(false);
                                                    }
                                                  }
                                                }}
                                                onBlur={() => {
                                                  const isValidCode = validDiscountCodes.find((discountCode) => {
                                                    if (discountCode.name.toLowerCase() === discountValue.toLowerCase()) {
                                                      setDiscountBeingUsed(discountCode);
                                                      return discountCode;
                                                    }
                                                  });
                                                  if (isValidCode) {
                                                    setValidDiscount(true);
                                                  } else {
                                                    setValidDiscount(false);
                                                  }
                                                }}
                                                placeholder="Enter discount code here"
                                              />

                                            </ListGroupItem>


                                            <ListGroupItem>NO EXPIRATION</ListGroupItem>
                                            <ListGroupItem>USER:PASS AUTHENTICATION</ListGroupItem>
                                            <ListGroupItem>STATIC, ROTATING, AND SITE-SPECIFIC PROXIES</ListGroupItem>
                                            <ListGroupItem>FOOTSITES, YEEZY SUPPLY, SUPREME, SHOPIFY, AND MORE</ListGroupItem>
                                          </ListGroup>
                                          <Card.Body>
                                            <Button
                                              className="purchase-button"
                                              onClick={handlePurchase}
                                            >
                                              PURCHASE
                                      </Button>
                                          </Card.Body>
                                        </Card>
                                      ) : (
                                          <Card className="purchase-card">
                                            {selectedProxyType}
                                          </Card>
                                        )}
                                    </>

                                  )}

                              </Row>
                              <Modal show={show} centered onHide={handleClose} className="proxy-modal">
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    {hasAgreedToTerms ? (<p>PURCHASE NOW</p>) : (<p>TERMS OF SALE</p>)}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {hasAgreedToTerms ? <Stripe selectedProduct={selectedProduct} userData={userData} isAuthenticated={isLoggedIn} discountAmount={discountBeingUsed.discount} discountValue={discountValue} /> : (<p>    Non-Refundable. Buyer understands that this product is considered digital goods, and once it has been purchased, the product will be delivered instantly, there will be no attempts of chargebacks, and there is no rescission of this agreement and no refund will be made.</p>)}
                                </Modal.Body>
                                <Modal.Footer>
                                  {hasAgreedToTerms ? (
                                    <Button
                                      variant="primary"
                                      className="purchase-button"
                                      onClick={() => {
                                        handleClose();
                                        setHasAgreedToTerms(false);
                                      }}
                                    >
                                      CANCEL
                                    </Button>
                                  ) : (
                                      <Button
                                        variant="primary"
                                        className="purchase-button"
                                        onClick={() => {
                                          setHasAgreedToTerms(true);
                                          PasswordService.DecreaseUses(validPassword, () => { }, () => { });
                                        }}
                                      >
                                        I AGREE
                                      </Button>
                                    )}
                                </Modal.Footer>
                              </Modal>
                            </Form>
                          </Col>
                        </Row>
                      </>
                    )}


                </Col>
              </Row>
            </Row>
          </>
        )}

    </Container>
  );
};

export default PasswordPage;
