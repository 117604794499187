import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class DCService {
  static async createDCCheckout(postData, onSuccess: any, onError: any) {
    // need to figure out what the discount code system is doing
    let url = getAPIPath('shopify/checkout')
    let opts = { withCredentials: true }
    axios.post(url, postData, opts)
      .then(onSuccess)
      .catch(onError)
    // let r = await axios(opts)
    // console.log(`create checkout resp:`,r.data)
    // return r.data
  }
  static async getPlans() {
    console.log(`getting plans from api`)
    let url = getAPIPath('shopify/stock')
    let r = await axios({ url, withCredentials: true })
    return r.data
  }

  static async getUserGroups() {
    let url = getAPIPath('shopify/mygroups')
    let r = await axios({ url, withCredentials: true })
    console.log(`getusergroups resp:`, r.data)
    return r.data
  }

  static async genRenewCheckout(id, code) {
    let url = getAPIPath('shopify/renew')
    let postData = { groupId: id, discountValue: code }
    let r = await axios.post(url, postData, { withCredentials: true })
    return r.data
  }
  static async getInvoice(id) {
    let url = getAPIPath('shopify/invoice/' + id)
    let postData = { groupId: id }
    let r = await axios({ url, withCredentials: true })
    return r.data

  }
}
