import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class DiscountService {
  static async Generate(data: any, onSuccess: any, onError: any) {
    await axios
      .post(getAPIPath('discount/generate'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetValid(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('discount/get/valid'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async UpdateRow(data: any, onSuccess: any, onError: any) {
    await axios
      .put(getAPIPath('discount/update'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async RemoveRow(id: any, onSuccess: any, onError: any) {
    await axios
      .delete(getAPIPath(`discount/${id}/remove`), {
        withCredentials: true,
      })
      .then(onSuccess)
      .catch(onError);
  }
}
