import axios from 'axios';
import getAPIPath from '../helpers/urlGenerator';

export default class ProxyService {
  static async VerifyIP(id: string, onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath(`/proxy/verify/${id}`), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GenerateProxy(data: any, onSuccess: any, onError: any) {
    await axios
      .post(getAPIPath('/proxy/generate/'), data, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }

  static async GetRemainingData(onSuccess: any, onError: any) {
    await axios
      .get(getAPIPath('/proxy/admin/remaining'), { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
  }
}
